import React, { useRef, useState } from 'react';
import MaterialTable from 'material-table';
import { TableLocalization } from 'common/tableConfig';
import { fetchURLs, fetchURL } from 'entities/urls/urls.api';
import { getRange, getItemsCount } from '../../../pages.utils';
import getTableProps from './UrlAnalysisTable.tableProps';

export default function UrlAnalysisTable () {
  const localization = TableLocalization();
  const tableRef = useRef(null);
  const refreshTableData = () => tableRef.current.onQueryChange();
  const tableProps = getTableProps({ refreshTableData });
  const [isLoading, setIsLoading] = useState(true);
  const [resetPageIndex, setResetPageIndex] = useState(false);
  const [pageSize, setPageSize] = useState(tableProps.options.pageSize);

  const getTableData = async (tableQuery) => {
    setIsLoading(true);
    const { pageSize, page } = tableQuery;
    let pageIndex = page;

    // To prevent out of bounds errors, pageIndex
    // should be reset whenever fetching a new query.
    if (resetPageIndex) {
      pageIndex = 0;
      setResetPageIndex(false);
    }

    try {
      const urlsResponse = await fetchURLs({ range: getRange(pageIndex, pageSize) });
      const urlPromises = urlsResponse.data.map((url) => fetchURL(url.id));
      const urlResponses = await Promise.all(urlPromises);
      const updatedData = urlsResponse.data.map((url, index) => {
        return {
          ...url,
          url_endpoint_data: {
            current_analysis: urlResponses[index]?.data?.current_analysis,
            owner: urlResponses[index]?.data?.owner
          }
        };
      }
      );
      setIsLoading(false);
      return {
        data: updatedData,
        page: pageIndex,
        totalCount: getItemsCount(urlsResponse.headers['content-range'])
      };
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  return (
    <MaterialTable
      title=''
      {...tableProps}
      options={{ ...tableProps.options, pageSize, toolbar: false }}
      components={{ ...tableProps.components }}
      onChangeRowsPerPage={setPageSize}
      data={getTableData}
      tableRef={tableRef}
      isLoading={isLoading}
      localization={localization}
    />
  );
}
