export const jsonToURI = (json) => encodeURIComponent(JSON.stringify(json));

export const getSnackBarProps = (error, setError) => ({
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  open: error,
  autoHideDuration: 6000,
  onClose: () => setError(''),
  message: error
});
