import { FormControl, InputLabel, OutlinedInput, Grid } from '@material-ui/core';
import React, { useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TwitterPicker } from 'react-color';
import makeStyles from './styles';

const useStyles = makeStyles();

function reducer (state, action) {
  switch (action.type) {
    case 'updateTag':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function AddUser (props) {
  const classes = useStyles();
  const [tag, dispatch] = useReducer(reducer, props.defaultTag);
  const [t] = useTranslation();
  const updateTag = useCallback((e) => {
    dispatch({
      type: 'updateTag',
      payload: {
        [e.target.name]: e.target.value
      }
    });
  }, [dispatch]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    props.onSubmit(tag);
  }, [props, tag]);

  const handleChange = (color) => {
    dispatch({
      type: 'updateTag',
      payload: {
        color: color.hex.replace('#', '')
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-tag">
          {t('tag')}
        </InputLabel>

        <OutlinedInput
          margin="normal"
          required
          fullWidth
          name="text"
          labelWidth={23}
          type="text"
          autoFocus
          id="text"
          autoComplete="text"
          value={tag.text}
          onChange={updateTag}
        />
      </FormControl>
      <FormControl
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-tag">
          {t('description')}
        </InputLabel>

        <OutlinedInput
          margin="normal"
          required
          fullWidth
          name="description"
          labelWidth={77}
          type="text"
          autoFocus
          id="description"
          autoComplete="description"
          value={tag.description}
          onChange={updateTag}
        />
      </FormControl>
      <Grid container justify="space-between">
        <p>{t('color')}</p>
        <div className={classes.colorPickerDemo} style={{ backgroundColor: `#${tag.color}` }}></div>
      </Grid>
      <Grid container justify="center">
        <TwitterPicker
          onChangeComplete={handleChange}
          triangle="hide"
          color={`#${tag.color}`}
          width="80%"
          styles={{
            default: {
              swatch: {
                borderRadius: '50%'
              }
            }
          }}
        />
      </Grid>
      <button ref={props.submitButtonRef} style={{ display: 'none' }} type='submit'></button>
    </form>
  );
}

AddUser.defaultProps = {
  defaultTag: {
    text: '',
    description: '',
    color: '0693E3'
  }
};

AddUser.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonRef: PropTypes.any,
  defaultTag: PropTypes.shape({
    text: PropTypes.string,
    description: PropTypes.string,
    color: PropTypes.string
  })
};

export default AddUser;
