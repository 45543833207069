import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles((theme) => ({
  archiveMenu: {
    marginTop: '20px',
    marginRight: '40px',
    borderTop: '1px solid lightgrey',
    borderRight: '1px solid lightgrey',
    overflowY: 'auto',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#ddd'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#999'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#666'
    }
  },
  treeView: {
    flexGrow: '1',
    paddingTop: '20px',
    height: '500px',
    marginLeft: '50px'
  },
  sidebarHeader: {
    color: '#808080c7',
    fontSize: '0.82rem',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    padding: '50px'
  },
  warningText: {
    backgroundColor: '#e7e9ed',
    padding: 20
  },
  infoIcon: {
    width: 15,
    height: 15,
    marginRight: 5
  }
}));
