import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinearProgress, Typography, Tooltip, IconButton } from '@material-ui/core';
import { ANALYSIS_STATUS, SAFETY_LABELS } from 'entities/analysis/analysis.constants';
import { InfoIcon } from 'common/Icons';
import { TOOLTIP_STATUS } from './StatusProgress.constants';
import { propTypes } from './StatusProgress.props';
import makeStyles from './StatusProgress.styles';

const useStyles = makeStyles();

const StatusProgress = ({ rowData }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  let progressProps = ['', '', '', 0, ''];

  /**
  * A file could have the following status:
  * "not safe" (detected as malware),
  * "safe" (detected as goodware by whitelist),
  * "unknown" (analyzed but not detected as anything"), --> safety is null
  * "not analyzed" (not scanned/analyzed),
  * "suspicious" (analyzed not "safe" but not detected as "not safe" either, flagged as suspicious by sandbox or similar probes)
  */

  if (!rowData.current_analysis) {
    progressProps = [
      SAFETY_LABELS.notAnalyzed,
      classes.notAnalyzed,
      classes.notAnalyzedBackground,
      TOOLTIP_STATUS.notAnalyzed
    ];
  } else {
    const status = rowData.current_analysis.status || null;
    const safety = rowData.current_analysis.safety || null;

    const safetyKey = safety ? safety.toLowerCase() : 'unknown';
    let safetyLabel = SAFETY_LABELS[safetyKey] || SAFETY_LABELS.unknown;
    let safetyClass = classes[safetyKey] || classes[safetyLabel];
    let statusBackground = classes[safetyKey + 'Background'] || classes[safetyLabel + 'Background'];
    let tooltip = t(TOOLTIP_STATUS[safetyKey]) || t(TOOLTIP_STATUS.unknown);

    if (status === ANALYSIS_STATUS.RUNNING) {
      safetyLabel = SAFETY_LABELS.running;
      safetyClass = classes.running;
      statusBackground = classes.runningBackground;
      tooltip = t(TOOLTIP_STATUS.running);
    }

    progressProps = [safetyLabel, safetyClass, statusBackground, tooltip];
  }

  const [label, color, backgroundColor, tooltip] = progressProps;

  return (
    <>
      <div className={classes.labelTooltip}>
        <Typography color="primary" classes={{ colorPrimary: color }}>{t(`${label}`)}</Typography>
        {t(tooltip) &&
          <Tooltip title={tooltip} classes={{ arrow: color, tooltip: classes.tooltip + ' ' + backgroundColor }} arrow placement='right'>
            <IconButton>
              <InfoIcon color="primary" className={color + ' ' + classes.infoIcon} />
            </IconButton>
          </Tooltip>}
      </div>
      <div>
        <LinearProgress
          color="primary"
          classes={{
            bar: backgroundColor + ' ' + classes.bar,
            colorPrimary: classes.grey,
            root: classes.rootProgress
          }}
          variant="determinate"
          value={100}
        />
      </div>
    </>
  );
};

StatusProgress.propTypes = propTypes;

export default StatusProgress;
