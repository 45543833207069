import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles((theme) => ({
  link: {
    color: 'inherit',
    textDecoration: 'none'
  },
  fileIcon: {
    position: 'absolute',
    left: -20,
    width: 20,
    height: 20,
    marginRight: '10px'
  },
  itemWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-20px'
  },
  fileName: {
    paddingLeft: '25px'
  }
}));
