import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import tableConfig from 'common/tableConfig';
import UsernameCell from './components/UserNameCell';
import ActionsMenu from './components/ActionsMenu';
import RoleColumn from './components/RoleColumn';

const dateFormat = 'MMM DD, YYYY, H:mm A';

const GetFullNameColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('full_name'),
    width: '15%',
    cellStyle: {
      ...tableConfig.options.cellStyle,
      maxWidth: 300,
      width: 300,
      padding: '0px 10px 0px 10px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      border: 'none'
    },
    // eslint-disable-next-line react/display-name
    render: rowData => {
      const fullname = (rowData.traits.name && rowData.traits.name.first && rowData.traits.name.last)
        ? `${rowData.traits.name.first} ${rowData.traits.name.last}`
        : rowData.traits.username;

      return <UsernameCell fullname={fullname} userid={rowData.id} username={rowData.traits.username} />;
    }
  };
};

const GetEmailAddressColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('email_address'),
    width: '15%',
    cellStyle: {
      ...tableConfig.options.cellStyle,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    // eslint-disable-next-line react/display-name
    render: rowData => rowData.traits.email || 'N/A'
  };
};

const GetUsernameColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('username'),
    width: '10%',
    cellStyle: {
      ...tableConfig.options.cellStyle,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    // eslint-disable-next-line react/display-name
    render: rowData => rowData.traits.username || 'N/A'
  };
};

const GetJoinedOnColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('joined_on'),
    width: '14%',
    // eslint-disable-next-line react/display-name
    render: rowData => <span>{rowData.joined_on ? moment(rowData.joined_on).format(dateFormat) : null}</span>
  };
};

const GetLastConnectedColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('last_connected_on'),
    width: '14%',
    // eslint-disable-next-line react/display-name
    render: rowData => <span>{rowData.last_connected_on ? moment(rowData.last_connected_on).format(dateFormat) : null}</span>
  };
};

const GetPasswordAttemptsColumns = () => {
  const [t] = useTranslation();
  return {
    title: t('password_attempts'),
    width: '7%',
    // eslint-disable-next-line react/display-name
    render: rowData => <span>{rowData.failed_connections} failed attempts</span>
  };
};

const GetFilesScannedColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('files_scanned'),
    width: '5%',
    // eslint-disable-next-line react/display-name
    render: rowData => <span>{rowData.files_scanned} files</span>
  };
};

const GetRoleColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('role'),
    width: '10%',
    // eslint-disable-next-line react/display-name
    render: ({ roles }) => <RoleColumn roles={roles}/>
  };
};

const GetActionsColumn = (fetchData, { selfUser }) => {
  const [t] = useTranslation();
  return {
    title: t('actions'),
    width: '5%',
    // eslint-disable-next-line react/display-name
    render: (rowData) => {
      return (
        <div>
          <ActionsMenu data={rowData} refreshTable={fetchData} selfUser={selfUser}/>
        </div>
      );
    }
  };
};

export const getColumns = (fetchData, { selfUser }) => {
  return [
    GetFullNameColumn(),
    GetEmailAddressColumn(),
    GetUsernameColumn(),
    GetJoinedOnColumn(),
    GetLastConnectedColumn(),
    GetPasswordAttemptsColumns(),
    GetFilesScannedColumn(),
    GetRoleColumn(),
    GetActionsColumn(fetchData, { selfUser })
  ];
};
