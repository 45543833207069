import React from 'react';
import { Link } from 'react-router-dom';
import { FileTextIcon } from 'common/Icons';
import { getColor } from '../../ArchivesSidebar.utils';
import { propTypes } from './FileItem.props';
import makeStyles from './FileItem.styles';

export default function FileItem ({ file, handleArchiveClick }) {
  const useStyles = makeStyles();
  const classes = useStyles();

  return (
    <Link className={classes.link} to={`/details/file/${file.id}`} onClick={handleArchiveClick}>
      <div className={classes.itemWrapper}>
        <FileTextIcon className={classes.fileIcon} color={getColor(file.safety)} />
        <div className={classes.fileName}>{file.name}</div>
      </div>
    </Link>
  );
}
FileItem.propTypes = propTypes;
