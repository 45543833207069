import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles((theme) => ({
  link: {
    color: 'inherit',
    textDecoration: 'none'
  },
  folderIcon: {
    position: 'absolute',
    left: 0,
    width: 25,
    height: 25,
    marginRight: '10px'
  },
  itemWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  folderName: {
    paddingLeft: '30px'
  }
}));
