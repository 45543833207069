import tableConfig from 'common/tableConfig';
import { getColumns } from './UrlAnalysisTable.columns';

export default ({ refreshTableData }) => ({
  columns: getColumns(refreshTableData),
  components: {
    ...tableConfig.components
  },
  options: {
    ...tableConfig.options,
    toolbar: true
  }
});
