import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  dialogIcon: {
    padding: 10,
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    position: 'relative',

    '& .MuiTableCell-body': {
      border: 'none'
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  title: {
    display: 'none'
  },
  searchField: {
    flexBasis: '50%',
    width: '75%'
  },
  toggleButton: {
    height: '51px',
    width: '179px',
    textTransform: 'capitalize',
    right: 0,
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent'
    }
  },
  tagInput: {
    width: 250
  },
  divider: {
    margin: '0px 20px'
  },
  gridToggle: {
    alignSelf: 'flex-end',
    marginBottom: '5px'
  },
  actions: {
    display: 'block'
  }
}));
