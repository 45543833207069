import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Link, Typography, Tooltip, IconButton } from '@material-ui/core';
import { InfoIcon, Close, ContentCopy, DownloadFolder, DownloadFile, Zip } from 'common/Icons';
import { downloadFileContent, fetchFileContent } from 'entities/files/files.api';
import ZipDownloadForm from 'pages/MyFiles/components/DownloadZip/ZipDownloadForm';
import DialogButtons from 'components/DialogButtons';
import { getAnalysisStatus } from 'entities/analysis/analysis.utils';
import { useClipboard, useDialog } from 'hooks';
import { useSnackSetState } from 'contexts/SnackContext';
import { getLastScan } from '../DetailsPanel/DetailsPanel.utils';
import { filterDataByType, getFlatList } from '../../Details.utils';
import NoResult from '../NoResult';
import { checkIsFailed, checkIsNotRunning, checkIsScanning, getAvProbesResults, getUnsafeAvsCount } from './Header.utils';
import { propTypes } from './Header.props';
import { STATUS_MSG, STATUS_PROGRESS_BAR, UNKNOWN } from './Header.constants';
import makeStyles from './Header.styles';

const useProgressBarStyles = ({ progress }) => {
  const useStyles = makeStyles();
  return useStyles({ progress });
};

const Header = ({ target = {}, results = [], isFile }) => {
  const [t] = useTranslation();
  const copy = useClipboard();
  const setSnack = useSnackSetState();
  const submitButtonRef = useRef(null);
  const avProbesResults = useMemo(() => getAvProbesResults(results), [results]);
  const resultsList = useMemo(() => getFlatList(avProbesResults), [avProbesResults]);
  const unsafeAvs = useMemo(() => getUnsafeAvsCount(resultsList), [resultsList]);
  const status = useMemo(() => getAnalysisStatus(target?.current_analysis?.safety, target?.current_analysis?.status), [target]);
  const isScanning = useMemo(() => checkIsScanning(target), [target]);
  const isNotRunning = useMemo(() => checkIsNotRunning(target), [target]);
  const isFailed = useMemo(() => checkIsFailed(target), [target]);
  const submittedBy = useMemo(() => target?.owner?.traits?.username ?? UNKNOWN, [target]);
  const classes = useProgressBarStyles({ progress: STATUS_PROGRESS_BAR[status] });
  const maxAvs = avProbesResults.length;
  const msg = STATUS_MSG[status];
  const ip = useMemo(() => !isFile && getFlatList(filterDataByType(results, 'IpInfo'))?.[0]?.ip, [isFile, results]);
  let domainName;
  const deletedAutomatically = (target.size === undefined && isFile);
  const canDownloadFile = (!deletedAutomatically && isFile);

  const copyHash = (hash, hashType) => {
    copy({ value: hash });
    setSnack({
      isOpen: true,
      title: hashType + ' ' + t('hash_copied_to_clipboard'),
      severity: 'success'
    });
  };

  try {
    domainName = new URL(target.url);
    domainName = domainName.hostname.replace('www.', '');
  } catch {
    domainName = null;
  };

  const downloadFile = async (file) => {
    try {
      const response = await fetchFileContent(file.id, {});
      downloadFileContent(response.data, file);
    } catch (error) {
      setSnack({
        isOpen: true,
        title: t('something_went_wrong'),
        severity: 'error'
      });
    }
  };

  const onDisplayZipDialog = useDialog(`download-as-zip-dialog-${target.id}`, {
    onClose: () => onDisplayZipDialog({ isOpen: false }),
    components: {
      Icon: <Zip className={classes.dialogIconSize} title={t('zip')} />,
      Title: <Typography variant='h4' className={classes.title} align="center">{t('the_file_you_are_about_to_download_may_contain_malicious_code')}</Typography>,
      Content: <ZipDownloadForm submitButtonRef={submitButtonRef} handleDialogClose={() => onDisplayZipDialog({ isOpen: false })} file={target}/>,
      Actions: (
        <div className={classes.dialogButtonsWrapper}>
        <DialogButtons
          confirmLabel={t('download')}
          cancelLabel={t('cancel')}
          onConfirm={() => submitButtonRef.current.click()}
          onCancel={() => onDisplayZipDialog({ isOpen: false })}
        />
        </div>
      )
    }
  });

  return (
    <div className={classes.container}>
      <div className={classes.progressWrapper}>
        <div className='progressDetails'>
          <span data-test-id='description' className='description'>
            {unsafeAvs + maxAvs < 1 ? (isFile ? `0 ${t('av', { count: 0 })}` : null) : `${unsafeAvs} / ${maxAvs} ${t('av', { count: maxAvs })}`}
          </span>
          <p className='percentage'>
            {status === 'scanning' ? t('scanning_three_dots') : t(`${status}`)}
          </p>
          <p className='info'>
            {t(msg)}
          </p>
        </div>
        <svg className={classes.progress}>
          {/* values cx, cy, r must be hard coded for firefox compatability, formula found in Header.styles.js  */}
          <circle cx='90' cy='90' r='66' className='baseCircle' />
          <circle cx='90' cy='90' r='66' className='progressCircle' />
        </svg>
      </div>
      <div className={classes.information}>
        <Grid container justify='space-between'>
          <Grid item className={classes.head}>
            {isScanning && <InfoIcon title={t('scanning_three_dots')} className='scan' size={16} />}
            {isNotRunning && <InfoIcon title={t('scan did_not_run')} color='#12214d' size={16} />}
            {isFailed && <Close title={t('scan_failed')} color='#fa4b54' size={16} />}
            {canDownloadFile
              ? <div className={classes.targetContainer}>
                  <span className={classes.targetName}>{target.name}</span>
                  <Tooltip title={t('download_file')} arrow placement='top'>
                  <IconButton
                    aria-label="Download File"
                    onClick={() => downloadFile(target)}
                  >
                    <DownloadFile color='#057FDC' className={classes.fileIcon} />
                  </IconButton>
                  </Tooltip>
                  <Tooltip title={t('download_as_zip')} arrow placement='top'>
                  <IconButton
                    aria-label="Download File as zip"
                    onClick={() => onDisplayZipDialog({ isOpen: true })}
                  >
                    <DownloadFolder color='#057FDC' className={classes.folderIcon} />
                  </IconButton>
                  </Tooltip>
                </div>
              : target.url}
            </Grid>
          {!deletedAutomatically
            ? <Grid item>
              {target.md5 && <Link href="#" underline='none' color='secondary' onClick={() => copyHash(target.md5, t('md5'))} className={classes.hashLinks}>
                {t('md5')} <ContentCopy title={t('copy_to_clipboard')} size={14} />
              </Link>}
              {target.sha1 && <Link href="#" underline='none' color='secondary' onClick={() => copyHash(target.sha1, t('sha1'))} className={classes.hashLinks}>
                {t('sha1')} <ContentCopy title={t('copy_to_clipboard')} size={14} />
              </Link>}
              {target.sha256 && <Link href="#" underline='none' color='secondary' onClick={() => copyHash(target.sha256, t('sha256'))} className={classes.hashLinks}>
                {t('sha256')} <ContentCopy title={t('copy_to_clipboard')} size={14} />
              </Link>}
            </Grid>
            : <Typography color="primary">
              <div className={classes.deletedFileInfo}>
                {t('file_deleted')}
                <Tooltip title={t('file_deleted_explanation')} arrow>
                  <IconButton>
                    <InfoIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              </div>
            </Typography>
          }
        </Grid>
        <div data-test-id='columns' className={classes.columns}>
          {!isFile && <div data-test-id='column' className={classes.column}>
            <p className='title'>{t('ip')}</p>
            <p className='content'>
              {ip ?? <NoResult message={t('not_defined_abbrev')} />}
            </p>
          </div>}
          {!isFile && <div data-test-id='column' className={classes.column}>
            <p className='title'>{t('domain_name')}</p>
            <p className='content'>
              {domainName ?? <NoResult message={t('not_defined_abbrev')} />}
            </p>
          </div>}
          {isFile && <div data-test-id='column' className={classes.column}>
            <p className='title'>{t('size_bytes')}</p>
            <p className='content'>{target.size}</p>
          </div>}
          {isFile && <div data-test-id='column' className={classes.column}>
            <p className='title'>{t('mime_type')}</p>
            <p className='content'>{target.mimetype}</p>
          </div>}
          <div data-test-id='column' className={classes.column}>
            <p className='title'>{t('submitted_by')}</p>
            <p className='content'>{submittedBy}</p>
          </div>
          <div data-test-id='column' className={classes.column}>
            <p className='title'>{t('last_scan')}</p>
            <p className='content'>{getLastScan(target)}</p>
          </div>
        </div>
      </div>
    </div>);
};

Header.propTypes = propTypes;

export default Header;
