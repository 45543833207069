import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import StatusProgress from 'pages/components/StatusProgress';
import Security from 'pages/components/Security';
import Privacy from 'pages/components/Privacy';
import { isNotNull } from 'utils/types';
import CellFallbackView from '../CellFallbackView';
import { getRows } from './ThreatTable.utils';
import { propTypes } from './ThreatTable.props';
import makeStyles from '../../styles';

const useStyles = makeStyles();

const ThreatTable = ({ data = [] }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const rows = useMemo(() => getRows(data), [data]);

  const cellProps = {
    className: classes.cell,
    component: 'th',
    scope: 'row'
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={10}>
        <div className={classes.fileInfoHeader}>
          <Typography variant="h4" className={classes.panelTitle}>{t('threats')}</Typography>
        </div>
        <Table className={classes.table} size="medium" aria-label={t('threats')}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell} align="left" style={{ width: '250px' }}>{t('probe')}</TableCell>
              <TableCell className={classes.headerCell} align="left" style={{ width: '150px' }}>{t('safety')}</TableCell>
              <TableCell className={classes.headerCell} align="left" style={{ width: '150px' }}>{t('security')}</TableCell>
              <TableCell className={classes.headerCell} align="left" style={{ width: '150px' }}>{t('privacy')}</TableCell>
              <TableCell className={classes.headerCell} align="left" style={{ width: '150px' }}>{t('threat_name')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(({ id, probeName, SafetyQualifier, SecurityQualifier, PrivacyQualifier, ThreatLabel }) => {
              return (
                <TableRow key={id}>
                  <TableCell {...cellProps}>{probeName}</TableCell>
                  <CellFallbackView {...cellProps} condition={isNotNull(SafetyQualifier)}>
                    <TableCell {...cellProps} className={classes.customCell}><StatusProgress rowData={{ current_analysis: { safety: SafetyQualifier } }} /></TableCell>
                  </CellFallbackView>
                  <CellFallbackView {...cellProps} condition={isNotNull(SecurityQualifier)}>
                    <TableCell {...cellProps} className={classes.customCell}><Security rowData={{ current_analysis: { security: SecurityQualifier } }} /></TableCell>
                  </CellFallbackView>
                  <CellFallbackView {...cellProps} condition={isNotNull(PrivacyQualifier)}>
                    <TableCell {...cellProps} className={classes.customCell}> <Privacy rowData={{ current_analysis: { privacy: PrivacyQualifier } }} /></TableCell>
                  </CellFallbackView>
                  <CellFallbackView {...cellProps} condition={isNotNull(ThreatLabel)}>
                    {ThreatLabel}
                  </CellFallbackView>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

ThreatTable.propTypes = propTypes;

export default ThreatTable;
