import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  dialogIconSize: {
    width: '4rem',
    height: '4rem'
  },
  rootItemCard: {
    position: 'relative'
  },
  divider: {
    margin: '0 20px'
  },
  refreshBtn: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main
  },
  probesContainer: {
    overflow: 'hidden'
  }
}));
