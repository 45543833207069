import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import AddTagUncontrolledInput from 'pages/components/AddTag/AddTagUncontrolledInput';
import { addTagToFile, deleteFile, deleteTagFromFile, downloadFileContent, fetchFileContent } from 'entities/files/files.api';
import { useAuthUser, useDialog } from 'hooks';
import { TagsIcon, DeleteFileIcon, Zip } from 'common/Icons';
import { startFileAnalysisRequest } from 'entities/analysisRequest/analysisRequest.api';
import MenuActions from 'components/MenuActions';
import { getHighestRole } from 'utils/roles';
import DialogButtons from 'components/DialogButtons';
import { propTypes } from './CellMenu.props';
import { MENU_KEYS } from './CellMenu.constants';
import ZipDownloadForm from 'pages/MyFiles/components/DownloadZip/ZipDownloadForm';
import { useSnackSetState } from 'contexts/SnackContext';
import DeleteFileContent from '../DeleteFileContent/DeleteFileContent';
import makeStyles from './CellMenu.style';

const useStyles = makeStyles();

export default function CellMenu ({
  file,
  tags,
  onChange
}) {
  const [t, i18n] = useTranslation();
  const valueRef = useRef(null);
  const submitButtonRef = useRef(null);
  const { auth } = useAuthUser();
  const classes = useStyles();
  const setSnack = useSnackSetState();

  const options = useMemo(() => {
    const roles = auth.roles.map(role => ({ name: role }));
    const highestRole = getHighestRole(roles);
    const deletedAutomatically = file.size === undefined;
    return [
      {
        key: MENU_KEYS.RESCAN,
        placeholder: t('rescan'),
        roles: ['analyst', 'admin'],
        visible: !deletedAutomatically,
        onClick: () => {
          startFileAnalysisRequest([file.id], {
            force: false,
            priority: 'MEDIUM',
            workflow: 'default',
            keep_file: true,
            workflow_options: {}
          }).then(() => onChange({}));
        }
      },
      {
        key: MENU_KEYS.TAGS,
        placeholder: t('modify_tags'),
        roles: ['admin', 'analyst'],
        visible: true,
        onClick: () => {
          onDisplayTagsDialog({ isOpen: true });
        }
      },
      {
        key: MENU_KEYS.COPYURL,
        placeholder: t('copy_url'),
        roles: ['admin', 'analyst', 'operator'],
        visible: true,
        onClick: () => {
          copyClipboard();
          setSnack({
            isOpen: true,
            title: t('url_copied_to_clipboard'),
            severity: 'success'
          });
        }
      },
      {
        key: MENU_KEYS.DOWNLOAD,
        placeholder: t('download_file'),
        roles: ['operator', 'analyst', 'admin'],
        visible: !deletedAutomatically,
        onClick: async () => {
          try {
            const response = await fetchFileContent(file.id, {});
            downloadFileContent(response.data, file);
          } catch (error) {
            console.log(error);
          }
          onChange({});
        }
      },
      {
        key: MENU_KEYS.DOWNLOAD_ZIP,
        placeholder: t('download_as_zip'),
        roles: ['operator', 'analyst', 'admin'],
        visible: !deletedAutomatically,
        onClick: async () => {
          onDisplayZipDialog({ isOpen: true });
        }
      },
      {
        key: MENU_KEYS.DELETE,
        placeholder: t('delete_file'),
        roles: ['admin'],
        visible: !deletedAutomatically,
        onClick: async () => {
          onDisplayDeleteDialog({ isOpen: true });
        }
      }

    ].filter(option => option.roles.includes(highestRole.roleName) && option.visible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file.id, i18n.language]);

  const handleDialogClose = () => {
    onDisplayTagsDialog({ isOpen: false });
    onDisplayZipDialog({ isOpen: false });
    onDisplayDeleteDialog({ isOpen: false });
  };

  const tagsContent = <AddTagUncontrolledInput defaultValue={tags} valueRef={valueRef} />;

  const onConfirm = () => {
    if (valueRef.current) {
      const tagsToRemove = tags.filter(
        tag => !valueRef.current.find(newTag => newTag.id === tag.id)
      );

      const tagsToAdd = valueRef.current.filter(
        newTag => !tags.find(tag => newTag.id === tag.id)
      );

      const promises = [];

      tagsToAdd.forEach((tag) => {
        promises.push(addTagToFile(file.id, {
          tag_id: tag.id
        }));
      });

      tagsToRemove.forEach((tag) => {
        promises.push(deleteTagFromFile(file.id, {
          tag_id: tag.id
        }));
      });

      Promise.all(promises)
        .then(() => onChange({}));
    }
  };

  const onConfirmDelete = async (fileId) => {
    try {
      const response = await deleteFile(fileId, { dry: false });
      if (response.status === 200) {
        setSnack({
          isOpen: true,
          title: t('file_deleted_successfully'),
          severity: 'success'
        });
      }
    } catch (error) {
      setSnack({
        isOpen: true,
        title: t('something_went_wrong'),
        message: t('file_could_not_be_deleted'),
        severity: 'warning'
      });
    }
    onChange({});
  };

  const onDisplayDeleteDialog = useDialog(`delete-file-dialog-${file.id}`, {
    onClose: handleDialogClose,
    components: {
      Icon: <DeleteFileIcon className={classes.dialogIconSize} color="#000000" />,
      Content: <DeleteFileContent fileId={file.id}/>,
      Actions: (
      <div className= {classes.deleteButtonsWrapper}>
       <DialogButtons
        confirmLabel={t('yes_delete_file')}
        cancelLabel={t('no')}
        onConfirm={() => onConfirmDelete(file.id)}
        onCancel={handleDialogClose}
        />
      </div>
      )
    }
  });

  const onDisplayTagsDialog = useDialog(`add-tag-to-file-dialog-${file.id}`, {
    onClose: handleDialogClose,
    components: {
      Icon: <TagsIcon className={classes.dialogIconSize} />,
      Title: <Typography variant="h5" align="center">{t('tags')}</Typography>,
      Content: tagsContent,
      Actions: (
          <DialogButtons
            confirmLabel= {t('save')}
            cancelLabel={t('cancel')}
            onConfirm={onConfirm}
            onCancel={handleDialogClose}
          />
      )
    }
  });

  const onDisplayZipDialog = useDialog(`download-as-zip-dialog-${file.id}`, {
    onClose: handleDialogClose,
    components: {
      Icon: <Zip className={classes.dialogIconSize} title={t('zip')} />,
      Title: <Typography variant='h4' className={classes.title} align="center">{t('the_file_you_are_about_to_download_may_contain_malicious_code')}</Typography>,
      Content: <ZipDownloadForm submitButtonRef={submitButtonRef} handleDialogClose={handleDialogClose} file={file} />,
      Actions: (
        <div className={classes.dialogButtonsWrapper}>
        <DialogButtons
          confirmLabel={t('download')}
          cancelLabel={t('cancel')}
          onConfirm={() => submitButtonRef.current.click()}
          onCancel={handleDialogClose}
        />
        </div>
      )
    }
  });

  const copyClipboard = () => {
    navigator.clipboard.writeText(`${window.location.origin}/details/file/${file.id}`);
  };

  return (
    options.length > 0
      ? <MenuActions options={options} />
      : null
  );
}

CellMenu.propTypes = propTypes;
