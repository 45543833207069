import { makeStyles, withStyles } from '@material-ui/core/styles';
import TabScrollButton from '@material-ui/core/TabScrollButton';

const commonCellStyle = {
  border: 'none'
};

export const StyledScrollButton = withStyles((theme) => ({
  root: {
    overflow: 'hidden',
    transition: 'width 0.3s',
    '&.Mui-disabled': {
      width: 0
    }
  }
}))(TabScrollButton);

export default theme => makeStyles((theme) => ({
  tableContainer: {
    '&::-webkit-scrollbar': {
      height: '10px'
    },
    '&::-webkit-scrollbar-track': {
      boxSshadow: 'inset 0 0 5px grey',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#cfcfcf',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#afafaf'
    }
  },
  defaultPageLayout: {
    flexGrow: 1,
    margin: '0px 50px',
    overflow: 'auto'
  },
  table: {
    // To align with page title, remove the table margin caused by child elements.
    marginLeft: '-15px',
    tableLayout: props => props.tableLayout
  },
  cell: {
    ...commonCellStyle,
    fontSize: '16px',
    overflow: 'hidden'
  },
  headerCell: {
    ...commonCellStyle,
    fontSize: '14px',
    color: theme.palette.text.secondary
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  detailsAppBar: {
    backgroundColor: '#f5f5f500',
    boxShadow: 'none'
  },
  fileInfoHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    margin: '15px 0 15px',
    lineHeight: '40px'
  },
  graphic: {
    marginLeft: 60
  },
  backLink: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  tabPanelDetails: {
    '&  > div': {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  detailsTableColumn: {
    marginRight: 15
  },
  panelTitle: {
    fontSize: 24,
    fontStyle: 'bold',
    letterSpacing: '0.6px'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginLeft: 45
  },
  iconButton: {
    height: 16,
    margin: theme.spacing(0, 1)
  },
  tableDisplay: {
    padding: 0,
    fontSize: 16,
    display: 'flex',
    flexDirection: 'column',

    '& *': {
      fontSize: theme.typography.htmlFontSize,
      margin: theme.spacing(1, 0),
      float: 'left'
    },

    '& dt': {
      clear: 'left',
      width: '150px',
      color: theme.palette.text.secondary
    },

    '& .labelChip': {
      fontWeight: 'normal',
      fontSize: 14,
      margin: '0 3px',
      padding: theme.spacing(0.75, 1)
    }
  },
  centerProgress: {
    height: '100vh',
    position: 'relative',
    '& > div': {
      position: 'absolute',
      top: '50%',
      left: '50%'
    }
  },
  totalLoadProgress: {
    height: '50vh',
    '& > div': {
      position: 'fixed',
      top: '50%',
      left: '56%'
    }
  },
  tabLabelAndIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > div ': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      lineHeight: '18px'
    },
    '& svg': {
      marginRight: 5,

      '& path': {
        stroke: 'inherit'
      }
    }
  },
  safetyText: {
    paddingRight: 8,
    lineHeight: 1.5
  },
  safetyLabelbox: {
    display: 'flex'
  },
  headingFile: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: 24
  },
  tagsContainer: {
    '& .labelChip': {
      marginRight: 5
    }
  },
  metaDataViewer: {
    backgroundColor: '#f3f3f3',
    padding: 10,
    whiteSpace: 'break-spaces',
    wordBreak: 'break-all'
  },
  fullMetaLink: {
    textDecoration: 'underline',
    color: '#1504fe',
    cursor: 'pointer'
  },
  metaButtonJustifyCenter: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  code: {
    background: theme.palette.text.secondary + 10,
    padding: '24px',
    overflow: 'auto',
    color: '#000'
  },
  noWrap: {
    whiteSpace: 'nowrap'
  },
  treeView: {
    flexGrow: 1,
    padding: '20px'
  },
  treeItemLabel: {
    color: theme.palette.text.secondary,
    paddingRight: 10
  },
  headerLabel: {
    backgroundColor: '#e5f2fb',
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  archiveBackButton: {
    marginLeft: '50px'
  },
  tabsHeaders: {
    '& .MuiTabs-scroller': {
      overflow: 'visible',
      '& .MuiTabs-scrollButton': {
        display: 'none'
      },
      '& .MuiTabs-scrollButtons': {
        display: 'flex'
      }
    }
  },
  tab: {
    paddingLeft: '18px'
  },
  urlLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center'
  },
  customCell: {
    paddingLeft: 0,
    ...commonCellStyle,
    fontSize: '16px',
    overflow: 'hidden',
    width: 200
  },
  errorIconLabel: {
    verticalAlign: 'middle',
    marginRight: '5px'
  }
}));
