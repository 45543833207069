import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableHead, TableRow, TableCell, TableBody, Grid } from '@material-ui/core';
import Panel from './components/Panel';
import Row from './components/Row';
import NewScanButton from './components/NewScanButton';
import { isGuestUser } from 'entities/auth/auth.utils';
import { COLUMNS } from './FileResults.constants';
import { propTypes } from './FileResults.props';
import makeStyles from '../../styles';

const useStyles = makeStyles();

const Results = ({ ids = [] }) => {
  const [t] = useTranslation();
  const isGuest = isGuestUser();
  const classes = useStyles();

  const getColumns = () => (isGuest ? COLUMNS.filter((column) => column.key !== 'actions') : COLUMNS);

  return (
    <>
      <div className={classes.page}>
        <Panel />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {getColumns().map(({ primaryText, key, width }) => (
                <TableCell key={key} className={`${classes.header} ${key === 'actions' && classes.actions}`} style={{ width: width }}>
                  {t(`${primaryText}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ids.map((id) => (
              <Row fileId={id} key={id} />
            ))}
          </TableBody>
        </Table>
      </div>
      <Grid item container justify="center" lg={12}>
        <Grid item xs={2}>
          <NewScanButton />
        </Grid>
      </Grid>
    </>
  );
};

Results.propTypes = propTypes;

export default Results;
