import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  pageTitle: {
    marginBottom: '20px'
  },
  divider: {
    margin: '0 20px'
  }
}));
