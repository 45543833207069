import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { fetchFile, fetchFileLatestResult } from 'entities/files/files.api';
import { fetchAnalysis } from 'entities/analysis/analysis.api';
import { fetchURL } from 'entities/urls/urls.api';
import { TYPES } from './Details.constants';
import { fetchArchivesAnalysis } from 'entities/analysisRequest/analysisRequest.api';

/**
 * The main reason for this hook is to hide the complexisty of getting URLs and Files details.
 * This bridge will just return two types of data: targetDetails, and targetResults
 * It could be for a file, or for a URL our caller should not worry about it.
 * If there are descrepencies, they should be handled here, the returned structure should be the same.
 * A boolean `isFile` is returned to be used in cases where we want to differentiate.
 */
export const useGetTargetData = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [targetResults, setTargetResults] = useState({});
  const [targetDetails, setTargetDetails] = useState({});
  const [showArchives, setShowArchives] = useState(false);
  const [archives, setArchives] = useState();
  const isFile = pathname.includes('/details/file/');

  const getFileAnalysisData = async () => {
    setIsFetching(true);
    Promise.all([fetchFile(id), fetchFileLatestResult(id)])
      .then(results => {
        setTargetDetails({ ...results[0].data, type: TYPES.FILE });
        setTargetResults(results[1].data);

        fetchArchivesAnalysis({ ...results[0].data, type: TYPES.FILE })
          .then(res => {
            if (res.parent?.status && res.parent.status !== results[0].data?.current_analysis?.status) {
              getFileData();
            }
            if (res.parent != null && res.children != null) {
              setArchives({ parent: res.parent, children: res.children });
              setShowArchives(true);
            }
          })
          .finally(() => {
            setIsFetching(false);
          });
      });
  };

  const getFileData = async () => {
    Promise.all([fetchFile(id), fetchFileLatestResult(id)])
      .then(results => {
        setTargetDetails({ ...results[0].data, type: TYPES.FILE });
        setTargetResults(results[1].data);
      });
  };

  const getURLData = async () => {
    const urlData = await fetchURL(id);
    const analysisId = urlData?.data?.current_analysis?.id;
    const analysisData = await fetchAnalysis(analysisId);
    setTargetDetails({ ...urlData.data, type: TYPES.URL });
    setTargetResults(analysisData?.data?.results);
    setIsFetching(false);
  };

  const fetch = () => isFile ? getFileAnalysisData() : getURLData();

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return {
    isFetching,
    targetDetails,
    targetResults,
    isFile,
    archives,
    showArchives
  };
};
