import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography
} from '@material-ui/core';
import {
  List
} from 'components/List';
import Item from './Item';
import AbortButton from './AbortButton';
import NewScanButton from '../Results/components/FileResults/components/NewScanButton';
import { FileTextIcon } from 'common/Icons';
import makeStyles from './styles';
/* context */
import useScanContext from 'contexts/ScanContext/useScanContext';
import { SCAN_ACTIONS } from 'contexts/ScanContext/ScanContext';

const useStyles = makeStyles();
export default function Scan () {
  const [t] = useTranslation();
  const [scanContext, setScanContext] = useScanContext();
  const navigate = useNavigate();
  const [finished, setFinished] = useState([]);
  const [finishedIds, setFinishedIds] = useState([]);
  const [stopScanning, setStopScanning] = useState(false);
  const [allScansAttempted, setAllScansAttempted] = useState(false);
  const idFiles = scanContext.files.map(f => f.tmpId);
  const addFinished = (tmpId, id) => {
    setFinishedIds([...finishedIds, id]);
    setFinished([...finished, tmpId]);
  };

  useEffect(() => {
    if (scanContext.files.length === finishedIds.length) {
      setAllScansAttempted(true);
    }
  }, [scanContext.files.length, finishedIds.length]);

  const classes = useStyles();

  useEffect(() => {
    if (idFiles.length === finished.length) {
      const isFinished = idFiles.every((val, index) => val === finished[index]);
      if (isFinished) {
        const searchQuery = `ids=${finishedIds.join(',')}`;
        setScanContext({ type: SCAN_ACTIONS.CLEAN_SCAN });
        navigate(`/results/file?${searchQuery}`, finishedIds);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finished, idFiles, setScanContext]);

  useEffect(() => {
    stopScanning && setScanContext({ type: SCAN_ACTIONS.CLEAN_SCAN });
  }, [stopScanning, setScanContext]);

  const abortCallback = () => {
    setStopScanning(true);
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
      <Grid item container xs={8} justify="center">
        <FileTextIcon className={classes.bigIcon} />
      </Grid>
      <Grid item xs={8}>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
        >
            {allScansAttempted ? t('scan_finished') : t('the_files_are_being_scanned_by_our_probes')}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <div>
          <Typography variant="h6" gutterBottom> {t('files')}</Typography>
        </div>
        <List aria-label="file preview list">
          {
            scanContext.files.map(file => (
              <Item
                file={file}
                key={file.instance.name}
                addFinished={addFinished}
                stopScanning={stopScanning}
              />
            ))
          }
        </List>
      </Grid>
      <Grid item container justify="center" xs={8}>
        <Grid item xs={4}> { allScansAttempted ? <NewScanButton stopScanning={setStopScanning} /> : <AbortButton onClick={abortCallback} /> }</Grid>
      </Grid>
    </Grid>
  );
}
