import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  addButton: {
    marginTop: theme.spacing(2),
    height: 51
  },
  toolbarContainer: {
    marginBottom: theme.spacing(2)
  },
  uppercase: {
    textTransform: 'capitalize'
  },
  toolbarItem: {
    paddingRight: 10
  },
  traitSelector: {
    '& .MuiInputBase-root': {
      height: 51
    }
  },
  errorBar: {
    '& .MuiPaper-root.MuiSnackbarContent-root': {
      backgroundColor: theme.palette.error.main
    }
  },
  iconStyle: { height: 25, width: 25, cursor: 'pointer' },
  avatar: { width: '100%', height: '100%' },
  colorPickerDemo: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    marginBottom: 10
  },
  dialogIconSize: {
    width: '4rem',
    height: '4rem'
  }
}));
