import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import makeStyles from './styles';

const useStyles = makeStyles();
export default function ProbeCardManifest ({
  version
}) {
  const [t] = useTranslation();
  const classes = useStyles();
  const typographyClasses = {
    overline: classes.overline
  };

  const typographyClassesWithEllipsis = {
    root: classes.typographyWithEllipsis,
    overline: classes.overline
  };

  return (
    <div className={classes.cardItemsWrapper}>
      <div className={classes.cardItems}>
        <Typography variant="caption" display="block" color="textSecondary" gutterBottom>
          {t('version')}
        </Typography>
        <Typography variant="overline" display="block" gutterBottom classes={typographyClasses}>
          {version.software}
        </Typography>
      </div>
      <div className={classes.itemManifest}>
        <Typography variant="caption" display="block" color="textSecondary" className={classes.captionTitle} gutterBottom>
          {t('database_version')}
        </Typography>
        <Typography
          variant="overline"
          display="block"
          gutterBottom
          classes={typographyClassesWithEllipsis}
          title={version.database}>
          {version.database || 'N/A'}
        </Typography>
      </div>
    </div>
  );
};

ProbeCardManifest.propTypes = {
  version: PropTypes.object
};
