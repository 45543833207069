import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { propTypes } from './DetailsPanel.props';
import { buildTags, hasTags, getFirstScan, getLastScan, getPreview } from './DetailsPanel.utils';
import ThreatTable from '../ThreatTable';
import NoResult from '../NoResult';
import PdfViewer from './components/PdfViewer';
import ScreenshotViewer from './components/ScreenshotViewer';
import { isDefined } from 'utils/types';
import makeStyles from '../../styles';

const useStyles = makeStyles();

const DetailsPanel = props => {
  const [t] = useTranslation();
  const classes = useStyles();

  const { data, threats, extracted } = props;

  const [preview, setPreview] = useState(null);
  const [screenshot, setScreenshot] = useState(null);

  useEffect(() => {
    const previewIDList = extracted.filter(res => res.artifacts[0]?.type === 'RAW_OUTPUT').filter(res => res.artifacts[0]?.name.startsWith('clean-'));

    const previewId = previewIDList[0]?.artifacts[0]?.id;
    const previewName = previewIDList[0]?.artifacts[0]?.name;

    if (isDefined(previewId)) {
      getPreview(previewId).then(url => {
        setPreview({
          url: url,
          id: previewId,
          name: previewName
        });
      });
    }

    const screenshotIDList = extracted.filter(res => res.artifacts[0]?.type === 'SCREENSHOT');

    const screenshotId = screenshotIDList[0]?.artifacts[0]?.id;
    const screenshotName = screenshotIDList[0]?.artifacts[0]?.name;

    if (isDefined(screenshotId)) {
      getPreview(screenshotId).then(url => {
        setScreenshot({
          url: url,
          id: screenshotId,
          name: screenshotName
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {threats.length > 0 &&
          <div>
            <ThreatTable data={threats} />
          </div>
        }
        <div className={classes.fileInfoHeader}>
          <Typography variant="h4" className={classes.panelTitle}>
           {t(data.name ? 'file_information' : 'url_information')}
          </Typography>
        </div>
        <div>
          <dl className={classes.tableDisplay}>
            {data.name && <div>
              <dt>{t('file_name')}</dt> <dd>{data.name}</dd>
            </div>}
            {data.url && <div>
              <dt>{t('url')}</dt> <dd>{data.url}</dd>
            </div>}
            {data.size && <div>
              <dt>{t('size_bytes')}</dt> <dd>{data.size}</dd>
            </div>}
            <div>
              <dt>{t('tags')}</dt> <dd>{hasTags(data) ? buildTags(data, null, false) : <NoResult message={t('no_tags_added')}/>}</dd>
            </div>
            {data.mimetype && <div>
              <dt>{t('mime_type')}</dt> <dd>{data.mimetype}</dd>
            </div>}
            <div>
              <dt>{t('first_scan')}</dt> <dd>{getFirstScan(data)}</dd>
            </div>
            <div>
              <dt>{t('last_scan')}</dt> <dd>{getLastScan(data)}</dd>
            </div>
            { preview &&
              <div>
                <dt>{t('document_preview')}</dt>
                  <dd>
                    <PdfViewer preview={preview} />
                  </dd>
              </div>
            }
            { screenshot &&
              <div>
                <dt>{t('screenshot')}</dt>
                  <dd>
                    <ScreenshotViewer preview={screenshot} />
                  </dd>
              </div>
            }
          </dl>
        </div>
      </Grid>
    </Grid>
  );
};

DetailsPanel.propTypes = propTypes;

export default DetailsPanel;
