import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles((theme) => ({
  filePreview: {
    width: '60vw',
    height: '60vh',
    border: 'solid 1px ' + theme.palette.text.secondary,
    padding: '10px',
    borderRadius: '5px'
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
