import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import GenericDialog from '../GenericDialog';
import { getDuration } from './StatusTable.utils';
import { propTypes } from './StatusTable.props';
import { ErrorWarningLine } from 'common/Icons';
import makeStyles from '../../styles';

const useStyles = makeStyles();

const StatusTable = ({ data }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const cellProps = {
    className: classes.cell,
    component: 'th',
    scope: 'row'
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <div className={classes.fileInfoHeader}>
          <Typography variant="h4" className={classes.panelTitle}>
            {t('probe_execution_status')}
          </Typography>
        </div>
        <Table className={classes.table} size="medium" aria-label={t('probe_execution_status')}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell} align="left">{t('name')}</TableCell>
              <TableCell className={classes.headerCell} align="left">{t('category')}</TableCell>
              <TableCell className={classes.headerCell} align="center">{t('return_code')}</TableCell>
              <TableCell className={classes.headerCell} align="center">{t('duration')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((d) => (
              <TableRow key={d.name}>
                <TableCell {...cellProps}>{d.probe.display_name}</TableCell>
                <TableCell {...cellProps}>{d.probe.category}</TableCell>
                <TableCell {...cellProps} align="center">{d.errors[0] ? <><ErrorWarningLine size={14} className={classes.errorIconLabel} /><GenericDialog id={d.id} label={d.status} data={d.errors[0]} /></> : d.status}</TableCell>
                <TableCell {...cellProps} align="center">{getDuration(d)} s</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

StatusTable.propTypes = propTypes;

export default StatusTable;
