import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Chip } from '@material-ui/core';
import { Date } from 'common/Table/Cells';
import StatusProgress from 'pages/components/StatusProgress';
import NameColumn from './components/NameColumn';
import CellMenu from './components/CellMenu';
import SubmittedBy from './components/SubmittedBy';

const GetUrlNameColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('url_name'),
    field: 'url',
    width: '35%',
    cellStyle: {
      maxWidth: 300,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      border: 'none'
    },
    // eslint-disable-next-line react/display-name
    render: rowData => <NameColumn rowData={rowData}/>
  };
};

const GetUrlOwnerColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('submitted_by'),
    width: '10%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: rowData =>
      <SubmittedBy owner={rowData.url_endpoint_data.owner}/>
  };
};

const GetUrlScanDate = () => {
  const [t] = useTranslation();
  return {
    title: t('last_scan_date'),
    field: 'date',
    width: '20%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: rowData => <Date rowData={rowData}/>
  };
};

const GetUrlScanStatus = () => {
  const [t] = useTranslation();
  return {
    title: t('safety'),
    width: '10%',
    render: function UrlScanStatus (rowData) {
      return <StatusProgress rowData={rowData.url_endpoint_data} />;
    }
  };
};

const GetUrlTagsColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('tags'),
    width: '15%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: rowData => {
      const tags = rowData.tags || [];
      const firstTag = tags[0];

      const remainingTags = tags.length - 1;

      if (!firstTag) {
        return <p>{t('no_tags')}</p>;
      }

      const remainingTagsText = (
        remainingTags
          ? ` +${remainingTags}`
          : null
      );

      const avatarStyle = {
        backgroundColor: `#${firstTag.color}`,
        width: 10,
        height: 10
      };

      return (
        <>
          <Chip
            key={firstTag.id}
            label={firstTag.text}
            avatar={
              <Avatar style={avatarStyle}>
                <div></div>
              </Avatar>
            }
          />
          <span>{remainingTagsText}</span>
        </>
      );
    }
  };
};

const GetMenuColumn = (refreshTableData) => {
  const [t] = useTranslation();
  return {
    title: t('actions'),
    width: '15%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: (rowData) => <CellMenu tags={rowData.tags} url={rowData} onChange={refreshTableData} />
  };
};

export const getColumns = (refreshTableData) => {
  return [
    GetUrlNameColumn(),
    GetUrlOwnerColumn(),
    GetUrlScanStatus(),
    GetUrlScanDate(),
    GetUrlTagsColumn(),
    GetMenuColumn(refreshTableData)
  ];
};
