import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AddTagInput from './AddTagInput';

const AddTagUncontrolledInput = ({
  defaultValue,
  valueRef
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    valueRef.current = value;
  }, [valueRef, value]);

  return <AddTagInput value={value} setValue={setValue} />;
};

AddTagUncontrolledInput.propTypes = {
  defaultValue: PropTypes.array.isRequired,
  valueRef: PropTypes.any.isRequired
};

export default AddTagUncontrolledInput;
