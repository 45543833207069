import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  button: {
    height: '51px',
    marginTop: '7px'
  },
  outlinedButton: {
    borderColor: theme.palette.secondary.main
  },
  dataPicker: {
    width: '153px'
  },
  statuSelector: {
    margin: 0,
    marginTop: '8px',
    '& #safety-selector': {
      padding: '16px 14px',
      width: '100px'
    }
  },
  searchText: {
    width: '660px'
  },
  sortSelector: {
    width: '140px',
    position: 'relative'
  },
  mainSelector: {
    width: '109px',
    '& .MuiSelect-selectMenu': {
      height: '14px',
      minHeight: 'auto'
    }
  },
  materialTable: {
    marginTop: 10
  }
}));
