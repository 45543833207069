import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { addTagToUrl, deleteTagFromUrl, deleteURL } from 'entities/urls/urls.api';
import { startURLAnalysisRequest } from 'entities/analysisRequest/analysisRequest.api';
import { TagsIcon, URLGlobe } from 'common/Icons';
import DialogButtons from 'components/DialogButtons';
import MenuActions from 'components/MenuActions';
import AddTagUncontrolledInput from 'pages/components/AddTag/AddTagUncontrolledInput';
import DeleteUrlContent from '../DeleteUrlContent/DeleteUrlContent';
import { useAuthUser, useDialog } from 'hooks';
import { useSnackSetState } from 'contexts/SnackContext';
import { getHighestRole } from 'utils/roles';
import { MENU_KEYS } from './CellMenu.constants';
import propTypes from './CellMenu.propTypes';
import makeStyles from './CellMenu.style';

const useStyles = makeStyles();

export default function CellMenu ({
  url,
  tags,
  onChange
}) {
  const [t, i18n] = useTranslation();
  const valueRef = useRef(null);
  const { auth } = useAuthUser();
  const classes = useStyles();
  const setSnack = useSnackSetState();

  const options = useMemo(() => {
    const roles = auth.roles.map(role => ({ name: role }));
    const highestRole = getHighestRole(roles);
    return [
      {
        key: MENU_KEYS.RESCAN,
        placeholder: t('rescan'),
        roles: ['analyst', 'admin'],
        visible: true,
        onClick: () => {
          startURLAnalysisRequest([url.id]).then(() => onChange({}));
        }
      },
      {
        key: MENU_KEYS.TAGS,
        placeholder: t('modify_tags'),
        roles: ['admin'],
        visible: true,
        onClick: () => {
          onDisplayTagsDialog({ isOpen: true });
        }
      },
      {
        key: MENU_KEYS.DELETE,
        placeholder: t('delete_url'),
        roles: ['admin'],
        visible: true,
        onClick: async () => {
          onDisplayDeleteDialog({ isOpen: true });
        }
      }
    ].filter(option => option.roles.includes(highestRole.roleName) && option.visible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url.id, i18n.language]);

  const handleDialogClose = () => {
    onDisplayTagsDialog({ isOpen: false });
    onDisplayDeleteDialog({ isOpen: false });
  };

  const content = <AddTagUncontrolledInput defaultValue={tags} valueRef={valueRef} />;

  const onConfirmTag = () => {
    if (valueRef.current) {
      const tagsToRemove = tags.filter(
        tag => !valueRef.current.find(newTag => newTag.id === tag.id)
      );

      const tagsToAdd = valueRef.current.filter(
        newTag => !tags.find(tag => newTag.id === tag.id)
      );

      const promises = [];

      tagsToAdd.forEach((tag) => {
        promises.push(addTagToUrl(url.id, {
          tag_id: tag.id
        }));
      });

      tagsToRemove.forEach((tag) => {
        promises.push(deleteTagFromUrl(url.id, {
          tag_id: tag.id
        }));
      });

      Promise.all(promises)
        .then(() => onChange({}));
    }
  };

  const onDisplayTagsDialog = useDialog(`add-tag-to-file-dialog-${url.id}`, {
    onClose: handleDialogClose,
    components: {
      Icon: <TagsIcon className={classes.dialogIconSize} />,
      Title: <Typography variant="h5" align="center">{t('tags')}</Typography>,
      Content: content,
      Actions: (
        <DialogButtons
          confirmLabel= {t('save')}
          cancelLabel={t('cancel')}
          onConfirm={onConfirmTag}
          onCancel={handleDialogClose}
        />
      )
    }
  });

  const onConfirmDeleteURL = async (fileId) => {
    try {
      const response = await deleteURL(fileId, { dry: false });
      if (response.status === 200) {
        setSnack({
          isOpen: true,
          title: t('url_deleted_successfully'),
          severity: 'success'
        });
      }
    } catch (error) {
      setSnack({
        isOpen: true,
        title: t('something_went_wrong'),
        message: t('url_could_not_be_deleted'),
        severity: 'warning'
      });
    }
    onChange({});
  };

  const onDisplayDeleteDialog = useDialog(`delete-url-dialog-${url.id}`, {
    onClose: handleDialogClose,
    components: {
      Icon: <URLGlobe className={classes.dialogIconSize} color="#000000" />,
      Content: <DeleteUrlContent urlId={url.id}/>,
      Actions: (
      <div className= {classes.deleteButtonsWrapper}>
       <DialogButtons
        confirmLabel={t('yes_delete_url')}
        cancelLabel={t('no')}
        onConfirm={() => onConfirmDeleteURL(url.id)}
        onCancel={handleDialogClose}
        />
      </div>
      )
    }
  });

  return (
    options.length > 0
      ? <MenuActions options={options} />
      : null
  );
}

CellMenu.propTypes = propTypes;
