import { useTranslation } from 'react-i18next';
import CustomContainer from './components/CustomContainer/CustomContainer';
import CustomPagination from './components/CustomPagination/CustomPagination';

// Base configuration to be inherited across different tables for consistency, overriden when needed.
export default {
  components: {
    Container: CustomContainer,
    Pagination: CustomPagination
  },
  options: {
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 30],
    sorting: false,
    toolbar: false,
    search: false,
    draggable: false,
    paging: true,
    cellStyle: {
      border: 'none',
      fontSize: '14px'
    },
    headerStyle: {
      color: '#79859F',
      border: 'none',
      fontSize: '0.8rem'
    }
  }
};

export function TableLocalization () {
  const [t] = useTranslation();
  const tableLocalization = {
    body: {
      emptyDataSourceMessage: t('no_records_to_display'),
      addTooltip: t('add'),
      deleteTooltip: t('delete'),
      editTooltip: t('edit'),
      filterRow: {
        filterPlaceHolder: '',
        filterTooltip: t('filter')
      }
    },
    editRow: {
      deleteText: t('are_you_sure_you_want_to_delete_this_row'),
      cancelTooltip: t('cancel'),
      saveTooltip: t('save')
    },
    pagination: {
      labelRowsSelect: t('rows'),
      labelRowsPerPage: t('rows_per_page'),
      firstAriaLabel: t('first_page'),
      firstTooltip: t('first_page'),
      previousAriaLabel: t('previous_page'),
      previousTooltip: t('previous_page'),
      nextAriaLabel: t('next_page'),
      nextTooltip: t('next_page'),
      lastAriaLabel: t('last_page'),
      lastTooltip: t('last_page')
    },
    toolbar: {
      addRemoveColumns: t('add_or_remove_columns'),
      showColumnsTitle: t('show_columns'),
      showColumnsAriaLabel: t('show_columns'),
      exportTitle: t('export'),
      exportAriaLabel: t('export'),
      exportName: t('export_as_csv'),
      searchTooltip: t('search'),
      searchPlaceholder: t('search')
    }
  };
  return tableLocalization;
}
