import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { fetchTags } from 'entities/tags/tags.api';
import { useAuthUser } from 'hooks';
import makeStyles from './styles';

const useStyles = makeStyles();

export const AddTagInputUsingId = ({
  value,
  setValue
}) => {
  const [tags, setTags] = useState();
  const { auth } = useAuthUser();
  const isAdvancedUser = auth.roles && (auth.roles.includes('analyst') || auth.roles.includes('admin'));

  useEffect(() => {
    if (isAdvancedUser) {
      fetchTags()
        .then(response => {
          setTags(response.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdvancedUser]);

  const valueWithName = tags ? value.map(item => tags.find(tag => tag.id === item)) : [];

  const setValueIds = (tags) => {
    setValue(tags.map(tag => tag.id));
  };

  return isAdvancedUser && <AddTagInput value={valueWithName} setValue={setValueIds} tags={tags} />;
};

AddTagInputUsingId.propTypes = {
  value: PropTypes.array,
  setValue: PropTypes.func.isRequired
};

const AddTagInput = ({
  value,
  setValue,
  tags
}) => {
  const [t] = useTranslation();
  const [fetchedTags, setFetchedTags] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (!tags) {
      fetchTags()
        .then(response => {
          setFetchedTags(response.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  const tagOptions = fetchedTags;
  const filteredTags = tagOptions.filter(tag => !value.find(selectedTag => selectedTag.id === tag.id));

  return (
    <Autocomplete
      multiple
      id="tags-standard"
      filterSelectedOptions
      value={value}
      className={classes.tagInput}
      onChange={(_, newValue) => {
        setValue(newValue);
      }}
      options={filteredTags}
      getOptionLabel={(option) => option ? option.text : ''}
      noOptionsText={t('no_options')}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label= {t('tags')}
          placeholder={t('add_tags')}
        />
      )}
    />
  );
};

AddTagInput.propTypes = {
  value: PropTypes.array,
  setValue: PropTypes.func.isRequired,
  tags: PropTypes.array
};

export default AddTagInput;
