export const getRows = data => {
  const threats = [];

  data.forEach(result => {
    const mergedResults = result.data?.filter(item => ['ThreatLabel', 'SafetyQualifier', 'SecurityQualifier', 'PrivacyQualifier'].includes(item.type));

    let safetyQualifier = null;
    let securityQualifier = null;
    let privacyQualifier = null;
    let threatLabel = null;

    mergedResults.forEach(item => {
      if (item.type === 'SafetyQualifier') {
        safetyQualifier = item.level;
      } else if (item.type === 'SecurityQualifier') {
        securityQualifier = item.level;
      } else if (item.type === 'PrivacyQualifier') {
        privacyQualifier = item.level;
      } else if (item.type === 'ThreatLabel') {
        threatLabel = item.name;
      }
    });

    const extras = [
      result.probe.version.software ? `Software version ${result.probe.version.software}` : '',
      result.probe.version.database ? `Database version ${result.probe.version.database}` : ''
    ].filter(extra => extra !== '');

    threats.push({
      SafetyQualifier: safetyQualifier,
      SecurityQualifier: securityQualifier,
      PrivacyQualifier: privacyQualifier,
      ThreatLabel: threatLabel,
      id: `${result.id}`,
      probeName: result.probe.display_name,
      extras: extras.join(' | ')
    });
  });

  return threats;
};
