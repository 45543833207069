import React from 'react';
import { useLocation } from 'react-router-dom';
import FileResults from './components/FileResults';
import URLResults from './components/URLResults';

export default function Results () {
  const { search, pathname } = useLocation();
  const isFile = pathname.includes('/file');
  const isURL = !isFile && pathname.includes('/url');
  const ids = isFile && search.replace('?ids=', '').split(',');
  const urlId = isURL && search.replace('?id=', '');

  return isFile ? <FileResults ids={ids}/> : <URLResults urlId={urlId}/>;
}
