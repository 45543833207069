import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { Avatar } from '@material-ui/core';

const LabelChip = ({ text, onDelete, color, showDelete }) => {
  const avatarStyle = {
    backgroundColor: `#${color}`,
    width: 10,
    height: 10
  };

  const getDeleteFunc = () => showDelete ? onDelete : null;

  return <Chip
    label={text}
    onDelete={getDeleteFunc()}
    className="labelChip"
    avatar={
      <Avatar
        style={avatarStyle}
      >
        &nbsp;
      </Avatar>
    }
  />;
};

LabelChip.defaultProps = {
  showDelete: true
};

LabelChip.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
  file: PropTypes.object,
  onDelete: PropTypes.func,
  color: PropTypes.string,
  showDelete: PropTypes.bool
};

export default LabelChip;
