import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Backdrop, Typography } from '@material-ui/core';
import { useSnackSetState } from 'contexts/SnackContext';
import { Antivirus, InfoIcon } from 'common/Icons';
import Loading from 'common/Loading';
import ProbeCard from 'common/probes/ProbeCard';
import { DialogTitle, DialogButtons, DialogContent } from 'common/probes/ProbeDialog';
import { updateProbe } from 'entities/probes/probes.api';
import useDialog from 'hooks/useDialog';
import SwitchProbeCard from './components/SwitchProbeCard/SwitchProbeCard';
import { getServerError } from 'pages/pages.utils';
import makeStyles from './styles';

const useStyles = makeStyles();
export default function StoreCard ({ action, probe, onCardAction }) {
  const [t] = useTranslation();
  const setSnack = useSnackSetState();
  const classes = useStyles();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [enablePossible, setEnablePossible] = useState(true);

  const fetchEnable = async () => {
    try {
      const response = await updateProbe(probe.id, { enabled: !probe.isEnabled() });
      setShowBackdrop(false);
      if (response.status === 200) {
        const selectedAction = probe.isEnabled() ? 'disable' : 'enable';
        setSnack({
          isOpen: true,
          title: probe.isEnabled() ? t('disable_a_probe') : t('enable_a_probe'),
          message: probe.isEnabled() ? t('probe_was_disabled_successfully', { name: probe.info.name }) : t('probe_was_enabled_successfully', { name: probe.info.name }),
          severity: 'success'
        });
        action(selectedAction, probe);
      }
    } catch (error) {
      const errorMsg = probe.isEnabled() ? t('could_not_disable_probe', { name: probe.info.name }) : t('could_not_enable_probe', { name: probe.info.name });
      const selectedAction = probe.isEnabled() ? 'enable' : 'disable';
      setShowBackdrop(false);
      setSnack({
        isOpen: true,
        title: t('something_went_wrong'),
        message: getServerError(error.data, errorMsg),
        severity: 'error'
      });
      action(selectedAction, probe);
    }
  };

  const fetchRestrictAccess = async () => {
    try {
      const response = await updateProbe(probe.id, { selected: !probe.enabledVersion.selected });
      setShowBackdrop(false);
      if (response.status === 200) {
        const selectedAction = probe.isFullAccess() ? 'restrictAccess' : 'fullAccess';
        setSnack({
          isOpen: true,
          title: probe.isFullAccess() ? t('restrict_access') : t('full_access'),
          message: probe.isFullAccess() ? t('probe_turned_on_restrict_access_mode_successfully', { name: probe.info.name }) : t('probe_turned_on_full_access_mode_successfully', { name: probe.info.name }),
          severity: 'success'
        });
        action(selectedAction, probe);
      }
    } catch (error) {
      const errorMsg = probe.isFullAccess() ? t('could_not_restrict_access_to', { name: probe.info.name }) : t('could_not_make_full_access_to', { name: probe.info.name });
      setShowBackdrop(false);
      setSnack({
        isOpen: true,
        title: t('something_went_wrong'),
        message: getServerError(error.data, errorMsg),
        severity: 'error'
      });
    }
  };

  const fetchUpdate = async () => {
    try {
      const [responseNextVersion, responseOldVersion] = await Promise.all([
        updateProbe(probe.latest.id, { enabled: true }),
        updateProbe(probe.id, { enabled: false })
      ]);
      setShowBackdrop(false);
      if (responseNextVersion.status === 200 && responseOldVersion.status === 200) {
        const selectedAction = 'update';
        setSnack({
          isOpen: true,
          title: t('update_probe'),
          message: t('probe_was_succesfully_updated_to', { name: probe.info.name, software_version: probe.latest.version.software }),
          severity: 'success'
        });
        action(selectedAction, probe.latest);
      }
    } catch (error) {
      const errorMsg = t('could_not_update_probe_to_version', { name: probe.info.name, software_version: probe.latest?.version?.software });
      setShowBackdrop(false);
      setSnack({
        isOpen: true,
        title: t('something_went_wrong'),
        message: getServerError(error, errorMsg),
        severity: 'error'
      });
    }
  };

  const onConfirmEnable = () => {
    onDisplayDialog({ isOpen: false });
    setShowBackdrop(true);
    fetchEnable();
  };

  const onConfirmRestrict = () => {
    onDisplayDialogRestrictMode({ isOpen: false });
    setShowBackdrop(true);
    fetchRestrictAccess();
  };

  const onConfirmUpdate = () => {
    onDisplayDialogRestrictMode({ isOpen: false });
    setShowBackdrop(true);
    fetchUpdate();
  };

  const actionTitle = probe.isEnabled() ? t('disable') : t('enable');
  const actionText = probe.isEnabled()
    ? t('the_probe_will_be_disabled_in_the_current_tenant')
    : t('the_probe_will_be_enabled_in_the_current_tenant');
  const restrictTitle = probe.isFullAccess() ? t('restrict_access') : t('make_full_access');
  const restrictText = probe.isFullAccess()
    ? `${probe.info.display_name} ` + t('will_be_moved_to_restricted_mode')
    : `${probe.info.display_name} ` + t('will_be_moved_to_full_mode');

  const onDisplayDialog = useDialog(`probe-${probe.id}`, {
    onClose: () => {
      onDisplayDialog({ isOpen: false });
    },
    components: {
      Icon: <Antivirus className={classes.dialogIconSize} />,
      Title: <DialogTitle title={t('do_you_want_to_action_the_probe', { action_title: actionTitle, display_name: probe.info.display_name })} />,
      ContentText: <DialogContent content={actionText} />,
      Actions: <DialogButtons
        confirmLabel={t('ok')} cancelLabel={t('cancel')}
        onConfirm={onConfirmEnable}
        onCancel={() => onDisplayDialog({ isOpen: false })} />
    }
  });

  const onDisplayDialogRestrictMode = useDialog(`probe-restrict-${probe.id}`, {
    onClose: () => {
      onDisplayDialogRestrictMode({ isOpen: false });
    },
    components: {
      Icon: <Antivirus className={classes.dialogIconSize} />,
      Title: <DialogTitle title={t('do_you_want_to_restrict_to', { restrict_title: restrictTitle, display_name: probe.info.display_name })} />,
      ContentText: <DialogContent content={restrictText} />,
      Actions: <DialogButtons
        confirmLabel={t('ok')} cancelLabel={t('cancel')}
        onConfirm={onConfirmRestrict}
        onCancel={() => onDisplayDialogRestrictMode({ isOpen: false })} />
    }
  });

  const onDisplayDialogUpdate = useDialog(`probe-update-${probe.info.display_name}`, {
    onClose: () => {
      onDisplayDialogUpdate({ isOpen: false });
    },
    components: {
      Icon: <Antivirus className={classes.dialogIconSize} />,
      Title: <DialogTitle title={t('do_you_want_to_update_probe', { display_name: probe.info.display_name, software_version: probe.latest.version.software })} />,
      ContentText: <DialogContent content={t('if_you_agree_qflow_will_update_probe', { display_name: probe.info.display_name })} />,
      Actions: <DialogButtons
        confirmLabel={t('ok')} cancelLabel={t('cancel')}
        onConfirm={onConfirmUpdate}
        onCancel={() => onDisplayDialogUpdate({ isOpen: false })} />
    }
  });

  const ribbonTextStyle = {
    caption: classes.ribbonText
  };

  const updateLink = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    onDisplayDialogUpdate({ isOpen: true });
  };

  const enableSwitch = {
    text: t('enable'),
    tooltip: {
      text: t('the_probe_can_be_switched_online_offline'),
      icon: <InfoIcon className={classes.infoIcon}/>
    },
    onClick: () => onDisplayDialog({ isOpen: true }),
    enabled: enablePossible,
    checked: (probe.isEnabled() && enablePossible)
  };
  const restrictAccessSwitch = {
    text: t('restrict_access'),
    tooltip: {
      text: t('full_access_is_enabled_by_default'),
      icon: <InfoIcon className={classes.infoIcon}/>,
      disabledText: t('this_option_is_available_on_enabled_probes')
    },
    onClick: () => onDisplayDialogRestrictMode({ isOpen: true }),
    enabled: (probe.isEnabled() && enablePossible),
    checked: !probe.isFullAccess()
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={showBackdrop}>
        <Loading size={75} />
      </Backdrop>
      { probe.isEnabled() && probe.isReadyForUpdate()
        ? <div className={classes.ribbon}>
          <Typography variant="caption" align="center" classes={ribbonTextStyle}>
            {t('new_version_available')} <a href="" onClick={updateLink}>{t('update')}</a>
          </Typography>
        </div>
        : null
      }
      <ProbeCard
        onClickCard={() => onCardAction(probe)}
        probe={probe}
        setEnablePossible={setEnablePossible}>
        <div className={`${classes.cardActions} ${probe.isEnabled() && classes.cardActionsEnabled}`}>
          <SwitchProbeCard switchInfo={enableSwitch}/>
          <SwitchProbeCard switchInfo={restrictAccessSwitch}/>
        </div>
      </ProbeCard>
  </>
  );
}

StoreCard.propTypes = {
  action: PropTypes.func.isRequired,
  onCardAction: PropTypes.func.isRequired,
  probe: PropTypes.object.isRequired
};
