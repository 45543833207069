import React from 'react';
import { Trans } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useAuthUser } from 'hooks';
import { ReactComponent as Logo } from 'assets/qflow_logo.svg';
import LanguageSwitcher from 'components/LanguageSwitcher';
import { InfoIcon, UserSettings } from 'common/Icons';
import LogoutButton from './components/LogoutButton';
import { AuthLink } from './components/AuthLink';
import Notification from './Notification';
import { ROUTE_PATHS } from 'utils/routes.constants';
import makeStyles from './styles';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';

const useStyles = makeStyles();

const LoginLink = () => {
  return <AuthLink path="login" text='login'/>;
};
const RegisterLink = () => {
  return <AuthLink path="register" text='register'/>;
};

const UserSettingsButton = () => {
  const navigate = useNavigate();
  const iconStyle = {
    width: '1.5rem',
    height: '1.5rem'
  };

  const onClick = () => {
    navigate(ROUTE_PATHS.USER_SETTINGS);
  };

  return (
    <IconButton onClick={onClick}>
      <UserSettings style={iconStyle}/>
    </IconButton>
  );
};
export default function Bar () {
  const classes = useStyles();
  const { auth } = useAuthUser();
  const { isSaasModeEnabled } = useAppConfigContext();
  const icon = () => {
    return (
      <div className={classes.boxIcon}>
        <InfoIcon className={classes.infoicon}/>
      </div>
    );
  };

  const isGuestUser = auth.roles && auth.roles.length === 0;

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.mainBar}>
        <div>
          <div className={classes.logo}>
            <Link to={ROUTE_PATHS.SCAN}>
              <Logo />
            </Link>
          </div>
        </div>
        <Notification />
        { (isGuestUser && !isSaasModeEnabled) && <div>
          <Typography>{icon()}
            <Trans i18nKey={'features_are_restricted'}>
              Features are restricted.
              <LoginLink/>
              or
              <RegisterLink/>
              for an account to gain full access
            </Trans>
          </Typography>
        </div> }
        <div className={classes.statusBar}>
          { !isGuestUser && <UserSettingsButton />}
          { !isGuestUser && <LogoutButton /> }
          <LanguageSwitcher />
        </div>
      </div>
    </Toolbar>
  );
}
