import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Filename,
  Size,
  Date
} from 'common/Table/Cells';
import CellMenu from './components/CellMenu';
import StatusProgress from 'pages/components/StatusProgress';
import Security from 'pages/components/Security';
import Privacy from 'pages/components/Privacy';
import { Avatar, Chip } from '@material-ui/core';
import SubmittedBy from './components/SubmittedBy';

const GetFilenameColumn = (searchQuery) => {
  const [t] = useTranslation();
  return {
    title: t('filename'),
    width: '35%',
    sorting: false,
    cellStyle: {
      maxWidth: 300,
      width: 300,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      border: 'none'
    },
    // eslint-disable-next-line react/display-name
    render: rowData => <Filename rowData={rowData} searchQuery={searchQuery}/>
  };
};

const GetOwnerColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('submitted_by'),
    width: '10%',
    sorting: false,
    cellStyle: {
      maxWidth: 130,
      width: 130
    },
    // eslint-disable-next-line react/display-name
    render: rowData =>
      <SubmittedBy owner={rowData?.file_endpoint_data?.owner}/>
  };
};

const GetStatusColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('safety'),
    width: '10%',
    sorting: false,
    cellStyle: {
      maxWidth: 130,
      width: 130
    },
    render: rowData => {
      return <StatusProgress rowData={rowData?.file_endpoint_data} />;
    }
  };
};

const GetSecurityColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('security'),
    width: '10%',
    sorting: false,
    cellStyle: {
      maxWidth: 130,
      width: 130
    },
    render: rowData => {
      return <Security rowData={rowData?.file_endpoint_data}/>;
    }
  };
};

const GetPrivacyColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('privacy'),
    width: '10%',
    sorting: false,
    cellStyle: {
      maxWidth: 130,
      width: 130
    },
    render: rowData => {
      return <Privacy rowData={rowData?.file_endpoint_data}/>;
    }
  };
};

const GetSizeColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('size'),
    width: '5%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: rowData => <Size rowData={rowData}/>
  };
};

const GetDateColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('last_scan_date'),
    field: 'date',
    width: '20%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: rowData => <Date rowData={rowData}/>
  };
};

const GetTagsColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('tags'),
    width: '20%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: rowData => {
      const tags = rowData.tags || [];
      const firstTag = tags[0];
      const remainingTags = tags.length - 1;

      if (!firstTag) {
        return (<span>{t('no_tags')}</span>);
      }

      const remainingTagsText = (
        remainingTags
          ? ` +${remainingTags}`
          : null
      );

      const avatarStyle = {
        backgroundColor: `#${firstTag.color}`,
        width: 10,
        height: 10
      };
      return (
        <>
          <Chip
            key={firstTag.id}
            label={firstTag.text}
            avatar={
              <Avatar style={avatarStyle}>
                <div></div>
              </Avatar>
            }
          />
          <span>{remainingTagsText}</span>
        </>
      );
    }
  };
};

const GetMenuColumn = (refreshTableData) => {
  const [t] = useTranslation();
  return {
    title: t('actions'),
    width: '20%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: (rowData) => <CellMenu tags={rowData.tags} file={rowData} onChange={refreshTableData} />
  };
};

export const getColumns = (refreshTableData, searchQuery) => {
  return [
    GetFilenameColumn(searchQuery),
    GetOwnerColumn(),
    GetStatusColumn(),
    GetSecurityColumn(),
    GetPrivacyColumn(),
    GetSizeColumn(),
    GetDateColumn(),
    GetTagsColumn(),
    GetMenuColumn(refreshTableData)
  ];
};
