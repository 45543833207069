export const getColor = safety => {
  switch (safety) {
    case 'SAFE':
      return '#167424';
    case 'UNDETERMINED':
    case 'CLEAN':
      return '#167424';
    case 'SUSPICIOUS':
      return '#ffa500';
    case 'UNSAFE':
      return '#ff0000';
    default:
      return '';
  }
};
