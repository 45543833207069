import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';
import { Avatar, Button, Chip, IconButton, Snackbar, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import { TagsIcon, TrashIcon } from 'common/Icons';
import DialogButtons from 'components/DialogButtons';
import PageHeader from 'pages/components/PageHeader';
import { createTag, deleteTag, fetchTags } from 'entities/tags/tags.api';
import { useDialog } from 'hooks';
import { getRange, getItemsCount, getServerError } from '../pages.utils';
import SearchBox from './components/SearchBox';
import { jsonToURI, getSnackBarProps } from './Tags.utils';
import { TableLocalization } from 'common/tableConfig';
import getTableProps from './tableProps';
import AddTag from './AddTag';
import makeStyles from './styles';

const useStyles = makeStyles();
const initialQuery = {};

export default function MyFiles () {
  const [t] = useTranslation();
  const localization = TableLocalization();
  const submitButtonRef = useRef();
  const tableRef = useRef(null);
  const classes = useStyles();
  const { options, actions, components } = getTableProps();
  const [fragment, setText] = useState('');
  const [error, setError] = useState('');
  const [pageSize, setPageSize] = useState(options.pageSize);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(initialQuery);
  const [resetPageIndex, setResetPageIndex] = useState(false);

  const refreshTableData = () => tableRef.current.onQueryChange();

  const onConfirm = () => {
    submitButtonRef.current.click();
  };

  const onSubmit = (tag) => {
    createTag(jsonToURI(tag))
      .then(() => refreshTableData())
      .catch((error) => {
        setError(getServerError(error.data, t('something_went_wrong')));
      });
  };

  const onSearch = (text) => {
    setSearchQuery({ text });
    setResetPageIndex(true);
    refreshTableData();
  };

  const onDelete = (tagId) => {
    deleteTag(tagId)
      .then(() => refreshTableData())
      .catch((error) => {
        setError(getServerError(error.data, t('something_went_wrong')));
      });
  };

  const content = <AddTag
    submitButtonRef={submitButtonRef}
    onSubmit={onSubmit}
  />;

  const onDisplayDialog = useDialog('tags-dialog', {
    onClose: () => {
      onDisplayDialog({ isOpen: false });
    },
    components: {
      Icon: <TagsIcon className={classes.dialogIconSize} />,
      Title: <Typography variant="h5" align="center">{t('add_a_new_tag')}</Typography>,
      Content: content,
      Actions: (
        <DialogButtons
          confirmLabel={t('add_tag')}
          cancelLabel={t('cancel')}
          onConfirm={onConfirm}
          onCancel={() => onDisplayDialog({ isOpen: false })}
        />
      )
    }
  });

  const columnsProps = [
    {
      title: t('name'),
      width: '35%',
      // eslint-disable-next-line react/display-name
      render: rowData => {
        const avatarStyle = {
          backgroundColor: `#${rowData.color}`
        };

        return (
          <Chip
            label={rowData.text}
            avatar={
              <Avatar>
                <div className={classes.avatar} style={avatarStyle}></div>
              </Avatar>
            }
          />
        );
      }
    },
    {
      title: t('actions'),
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (rowData) => {
        return (
          <>
            <div onClick={() => onDelete(rowData.id)}>
              <TrashIcon color="#000" className={classes.iconStyle} title={t('delete')}/>
            </div>
          </>
        );
      }
    }
  ];

  const setSearchText = (value) => {
    if (!value) {
      setSearchQuery({});
      refreshTableData();
    }
    setText(value);
  };

  const onSearchCallback = () => onSearch(fragment);

  const onCreateTag = () => onDisplayDialog({ isOpen: true });

  const getTableData = tableQuery => {
    setIsLoading(true);
    return new Promise((resolve) => {
      const { pageSize, page } = tableQuery;
      let pageIndex = page;
      // To prevent out of bounds errors, pageIndex
      // should be reset whenever fetching a new query.
      if (resetPageIndex) {
        pageIndex = 0;
        setResetPageIndex(false);
      }
      fetchTags({ ...searchQuery, range: getRange(pageIndex, pageSize) })
        .then(json => {
          resolve({
            data: json.data,
            page: pageIndex,
            totalCount: getItemsCount(json.headers['content-range'])
          });
          setIsLoading(false);
        });
    });
  };

  return (
    <Grid item xs={12}>
      <PageHeader title={t('tags')} />
      <Paper className={classes.paper} elevation={0}>
        <Grid container className={classes.toolbarContainer}>
          <Grid item xs={8}>
            <SearchBox
              stateSearch={{ fragment }}
              setText={setSearchText}
              onSearch={onSearchCallback}
            />
          </Grid>
          <Grid container item xs={4} justify="flex-end">
            <Button
              className={classes.addButton}
              size="medium"
              color="secondary"
              variant="contained"
              onClick={onCreateTag}>
             {t('add_tag')}
            </Button>
          </Grid>
        </Grid>
        <Snackbar
          {...getSnackBarProps(error, setError)}
          className={classes.errorBar}
          action={
            <>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setError('')}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
        <MaterialTable
          title=''
          columns={columnsProps}
          onChangeRowsPerPage={setPageSize}
          data={getTableData}
          options={{ ...options, pageSize }}
          actions={actions}
          components={components}
          tableRef={tableRef}
          isLoading={isLoading}
          localization={localization}
        />
      </Paper>
    </Grid>
  );
}
