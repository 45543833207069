import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinearProgress, Typography, Tooltip, IconButton } from '@material-ui/core';
import { ANALYSIS_STATUS, PRIVACY_LABELS } from 'entities/analysis/analysis.constants';
import { InfoIcon } from 'common/Icons';
import { TOOLTIP_STATUS } from './Privacy.constants';
import { propTypes } from './Privacy.props';
import makeStyles from './Privacy.styles';

const useStyles = makeStyles();

const Privacy = ({ rowData }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  let progressProps = ['', '', '', 0, ''];

  if (!rowData.current_analysis) {
    progressProps = [
      PRIVACY_LABELS.notAnalyzed,
      classes.notAnalyzed,
      classes.notAnalyzedBackground,
      TOOLTIP_STATUS.notAnalyzed
    ];
  } else {
    const status = rowData.current_analysis.status || null;
    const privacy = rowData.current_analysis.privacy || null;

    const privacyKey = privacy ? privacy.toLowerCase() : 'unknown';
    let privacyLabel = PRIVACY_LABELS[privacyKey] || PRIVACY_LABELS.unknown;
    let privacyClass = classes[privacyKey] || classes[privacyLabel];
    let statusBackground = classes[privacyKey + 'Background'] || classes[privacyLabel + 'Background'];
    let tooltip = t(TOOLTIP_STATUS[privacyKey]) || t(TOOLTIP_STATUS.unknown);

    if (status === ANALYSIS_STATUS.RUNNING) {
      privacyLabel = PRIVACY_LABELS.running;
      privacyClass = classes.running;
      statusBackground = classes.runningBackground;
      tooltip = t(TOOLTIP_STATUS.running);
    }

    progressProps = [privacyLabel, privacyClass, statusBackground, tooltip];
  }

  const [label, color, backgroundColor, tooltip] = progressProps;

  return (
    <>
      <div className={classes.labelTooltip}>
        <Typography color="primary" classes={{ colorPrimary: color }}>{t(`${label}`)}</Typography>
        {t(tooltip) &&
          <Tooltip title={tooltip} classes={{ arrow: color, tooltip: classes.tooltip + ' ' + backgroundColor }} arrow placement='right'>
            <IconButton>
              <InfoIcon color="primary" className={color + ' ' + classes.infoIcon} />
            </IconButton>
          </Tooltip>}
      </div>
      <div>
        <LinearProgress
          color="primary"
          classes={{
            bar: backgroundColor + ' ' + classes.bar,
            colorPrimary: classes.grey,
            root: classes.rootProgress
          }}
          variant="determinate"
          value={100}
        />
      </div>
    </>
  );
};

Privacy.propTypes = propTypes;

export default Privacy;
