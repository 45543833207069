import React from 'react';
import dayjs from 'dayjs';
import LabelChip from 'common/LabelChip';
import _ from 'lodash';
import { fetchFileContent } from 'entities/files/files.api';
import { createPDFBlobToURL } from 'utils/downloadUtil';

export const buildTags = (data, onTagDelete, showDelete = true) => {
  return data.tags.map(
    ({ id, text, color }) => {
      return (
        <LabelChip
          key={text}
          id={id}
          text={text}
          color={color}
          onDelete={() => onTagDelete(data.id, id)}
          showDelete={showDelete}
        />
      );
    }
  );
};

export const hasTags = data => data.tags.length > 0;

const formatDate = date => dayjs(date).format('MMM D, YYYY H:mm A');

export const getFirstScan = data => {
  const firstItem = data?.analysis[0];
  if (firstItem) {
    return formatDate(firstItem.created_at);
  }
  return formatDate(data.timestamp_first_scan);
};

export const getLastScan = data => {
  const lastItem = _.last(data.analysis);
  if (lastItem) {
    return formatDate(lastItem.created_at);
  }
  return formatDate(data.timestamp_first_scan);
};

export const getPreview = async id => {
  const response = await fetchFileContent(id, {});
  return createPDFBlobToURL({ stream: response.data });
};
