import React from 'react';
import { Link } from 'react-router-dom';
import { Zip } from 'common/Icons';
import { getColor } from '../../ArchivesSidebar.utils';
import { propTypes } from './FolderItem.props';
import makeStyles from './FolderItem.styles';

export default function FolderItem ({ archives, handleArchiveClick }) {
  const useStyles = makeStyles();
  const classes = useStyles();

  return (
    <Link className={classes.link} to={`/details/file/${archives.parent.id}`} onClick={handleArchiveClick} >
      <div className={classes.itemWrapper}>
        <Zip className={classes.folderIcon} color={getColor(archives.parent.safety)} />
        <div className={classes.folderName}>{archives.parent.name}</div>
      </div>
    </Link>
  );
}
FolderItem.propTypes = propTypes;
