import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinearProgress, Typography, Tooltip, IconButton } from '@material-ui/core';
import { ANALYSIS_STATUS, SECURITY_LABELS } from 'entities/analysis/analysis.constants';
import { InfoIcon } from 'common/Icons';
import { TOOLTIP_STATUS } from './Security.constants';
import { propTypes } from './Security.props';
import makeStyles from './Security.styles';

const useStyles = makeStyles();

const Security = ({ rowData }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  let progressProps = ['', '', '', 0, ''];

  if (!rowData.current_analysis) {
    progressProps = [
      SECURITY_LABELS.notAnalyzed,
      classes.notAnalyzed,
      classes.notAnalyzedBackground,
      TOOLTIP_STATUS.notAnalyzed
    ];
  } else {
    const status = rowData.current_analysis.status || null;
    const security = rowData.current_analysis.security || null;

    const securityKey = security ? security.toLowerCase() : 'unknown';
    let securityLabel = SECURITY_LABELS[securityKey] || SECURITY_LABELS.unknown;
    let securityClass = classes[securityKey] || classes[securityLabel];
    let statusBackground = classes[securityKey + 'Background'] || classes[securityLabel + 'Background'];
    let tooltip = t(TOOLTIP_STATUS[securityKey]) || t(TOOLTIP_STATUS.unknown);

    if (status === ANALYSIS_STATUS.RUNNING) {
      securityLabel = SECURITY_LABELS.running;
      securityClass = classes.running;
      statusBackground = classes.runningBackground;
      tooltip = t(TOOLTIP_STATUS.running);
    }

    progressProps = [securityLabel, securityClass, statusBackground, tooltip];
  }

  const [label, color, backgroundColor, tooltip] = progressProps;

  return (
    <>
      <div className={classes.labelTooltip}>
        <Typography color="primary" classes={{ colorPrimary: color }}>{t(`${label}`)}</Typography>
        {t(tooltip) &&
          <Tooltip title={tooltip} classes={{ arrow: color, tooltip: classes.tooltip + ' ' + backgroundColor }} arrow placement='right'>
            <IconButton>
              <InfoIcon color="primary" className={color + ' ' + classes.infoIcon} />
            </IconButton>
          </Tooltip>}
      </div>
      <div>
        <LinearProgress
          color="primary"
          classes={{
            bar: backgroundColor + ' ' + classes.bar,
            colorPrimary: classes.grey,
            root: classes.rootProgress
          }}
          variant="determinate"
          value={100}
        />
      </div>
    </>
  );
};

Security.propTypes = propTypes;

export default Security;
