import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Checkbox, Button, Divider } from '@material-ui/core';
import Alert from 'components/Alert/Alert';
import Loading from 'common/Loading';
import ProbeModel from 'common/probes/models/ProbeModel';
import StoreCard from 'common/probes/StoreCard';
import BackToTopButton from 'components/BackToTopButton';
import { fetchCatalogProbes, refreshCatalog, DEFAULT_CATALOG } from 'entities/catalogs/catalogs.api';
import { fetchSelfUser } from 'entities/users/users.api';
import { isPlatformAdmin } from 'entities/users/users.utils';
import PageHeader from 'pages/components/PageHeader';
import NoProbesAvailable from './components/NoProbesAvailable';
import { ROUTE_PATHS } from 'utils/routes.constants';
import makeStyles from './styles';

const useStyles = makeStyles();

export default function StoreView () {
  const [t] = useTranslation();
  const [filterLocals, setFilterLocals] = useState(false);
  const [isFetching, setIsfetching] = useState(true);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [probesList, setProbesList] = useState([]);
  const classes = useStyles();
  const [isRefreshing, setisRefreshing] = useState(false);

  useEffect(() => {
    fetchSelfUser()
      .then(response => {
        const user = response.data;
        if (!isPlatformAdmin(user)) {
          navigate(ROUTE_PATHS.FORBIDDEN);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCatalogProbes(DEFAULT_CATALOG)
      .then(res => {
        setIsfetching(false);
        if (res.data?.length > 0) {
          setData(res.data);
          setProbesList(res.data.map(d => new ProbeModel(d)));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnRefresh = () => {
    setisRefreshing(true);
    refreshCatalog(DEFAULT_CATALOG)
      .then(() => fetchCatalogProbes(DEFAULT_CATALOG)).then(() => setisRefreshing(false));
  };

  const areAllDisabled = () => probesList.every(prob => !prob.isEnabled());

  const onClickCard = (probe) => {
    navigate(`/probes/store/${probe.id}`);
  };

  const onfilterLocals = () => {
    setFilterLocals((prev) => !prev);
  };

  const updateProbe = (action, probe) => {
    setProbesList(oldProbesList => {
      switch (action) {
        case 'enable': return oldProbesList.map(oldProbe => {
          if (probe.id === oldProbe.id) {
            const probeFromResponse = data.find(d => d.info.display_name === probe.info.display_name);
            const updatedProbe = new ProbeModel(probeFromResponse);
            updatedProbe.toEnable();
            return updatedProbe;
          }

          return oldProbe;
        });
        case 'disable': return oldProbesList.map(oldProbe => {
          if (probe.id === oldProbe.id) {
            const probeFromResponse = data.find(d => d.info.display_name === probe.info.display_name);
            const updatedProbe = new ProbeModel(probeFromResponse);
            updatedProbe.toDisable();
            return updatedProbe;
          }

          return oldProbe;
        });
        case 'restrictAccess': return oldProbesList.map(oldProbe => {
          if (probe.id === oldProbe.id) {
            const probeFromResponse = data.find(d => d.info.display_name === probe.info.display_name);
            const updatedProbe = new ProbeModel(probeFromResponse);
            updatedProbe.enabledVersion = { ...probe.enabledVersion };
            updatedProbe.restrictAccess();
            return updatedProbe;
          }

          return oldProbe;
        });
        case 'fullAccess': return oldProbesList.map(oldProbe => {
          if (probe.id === oldProbe.id) {
            const probeFromResponse = data.find(d => d.info.display_name === probe.info.display_name);
            const updatedProbe = new ProbeModel(probeFromResponse);
            updatedProbe.enabledVersion = { ...probe.enabledVersion };
            updatedProbe.fullAccess();
            return updatedProbe;
          }

          return oldProbe;
        });
        case 'update': return oldProbesList.map(oldProbe => {
          // becareful with this version
          if (probe.id === oldProbe.latest.id) {
            const probeFromResponse = data.find(d => d.info.display_name === oldProbe.info.display_name);
            const updatedProbe = new ProbeModel(probeFromResponse);
            updatedProbe.toUpdate();
            return updatedProbe;
          }

          return oldProbe;
        });
      }
    });
  };

  const classesItem = {
    root: classes.rootItemCard
  };

  return (
    <Grid classes={{ root: classes.probesContainer }}>
      { isFetching && <Loading align="center" size={75} /> }
      { (!isFetching && probesList.length > 0) && (
          <>
            <Grid container justify='space-between'>
              <Grid item xs={12} sm={12} md={6}>
                <PageHeader title={t('probes')}>
                  {areAllDisabled() && (
                    <Alert type={Alert.TYPES.WARNING}>
                      {t('scan_is_not_available_as_no_probes_are_enabled')}
                    </Alert>
                  )}
                </PageHeader>
              </Grid>
              <Grid item align="right" alignItems="center">
                <Grid container>
                  <Grid item>
                    <Checkbox color="primary" checked={filterLocals} onChange={onfilterLocals} />
                    <Typography variant="body1" component="span" gutterBottom>{t('show_only_enabled_probes')}</Typography>
                  </Grid>
                  <Divider classes={{ root: classes.divider }} orientation="vertical" flexItem />
                <Grid item>
                  {isRefreshing ? <Loading /> : <Button classes={{ root: classes.refreshBtn }} onClick={handleOnRefresh} variant="outlined">{t('refresh')}</Button>}
                </Grid>
                </Grid>
                <BackToTopButton/>
            </Grid>
          </Grid>
            <Grid container justify="flex-start" spacing={2}>
              { // display locals/enabled probes
                filterLocals && probesList.filter(probe => {
                  return probe.isEnabled();
                }).map(probe => (
                  <Grid key={probe.id} item classes={classesItem}>
                    <StoreCard
                      probe={probe}
                      action={updateProbe}
                      onCardAction={onClickCard}
                    />
                  </Grid>
                ))
              }
              { // display all probes
                !filterLocals && probesList.map(probe => (
                  <Grid key={probe.id} item classes={classesItem}>
                    <StoreCard
                      probe={probe}
                      action={updateProbe}
                      onCardAction={onClickCard}
                    />
                  </Grid>
                ))
              }
            </Grid>
          </>
      )}
      { (!isFetching && probesList.length === 0) && <NoProbesAvailable onRefresh={handleOnRefresh}/> }
    </Grid>
  );
};
