import axios from 'axios';

function SetupInterceptors () {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        if (window.location.pathname !== '/signin') {
          window.location.replace('./signin');
        }
      }
      return Promise.reject(error);
    }
  );
}

export default SetupInterceptors;
