import React from 'react';
import PropTypes from 'prop-types';
import { CardContent, Chip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import ProbeCardManifest from './ProbeCardManifest';
import ProbeCardStatus from './ProbeCardStatus';
import makeStyles from './styles';

export default function ProbeCardContent ({ probe, setEnablePossible }) {
  const { id, version, licenseInfo } = probe;
  const title = probe?.info?.display_name;
  const description = probe?.info?.summary;
  const category = probe?.info?.category;

  const useStyles = makeStyles();
  const classes = useStyles();

  return (
    <CardContent classes={{ root: classes.cardContentRoot }}>
      <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
        {title}
      </Typography>
      <Chip label={category} size="small" aria-label="category" className={`${classes.chip} ${classes[category]}`}/>
      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        classes={{ body2: clsx(classes.summaryHeight, classes.lineClamp2) }}>
        {description}
      </Typography>
      <ProbeCardManifest version={version} />
      { licenseInfo && <ProbeCardStatus id={id} licenseInfo={licenseInfo} setEnablePossible={setEnablePossible} probe={probe}/> }
    </CardContent>
  );
}

ProbeCardContent.propTypes = {
  setEnablePossible: PropTypes.func,
  probe: PropTypes.object
};
