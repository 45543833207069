export const buildFetchQuery = (state = {}) => {
  const { searchText, status, sort, since, until, safety, tags } = state;
  const mainChose = state.main;
  const dataFrom = since || '';
  const dataTo = until || '';
  let stateWithoutEmptyFields = {};
  if (Object.keys(state).length !== 0) {
    const finallState = {
      [mainChose]: searchText,
      status,
      sort,
      since: dataFrom,
      until: dataTo,
      safety,
      tags: tags.reduce(
        (result, tag) => `${result}${tag},`,
        ''
      )
    };
    for (const property in finallState) {
      if (finallState[property] !== '') {
        stateWithoutEmptyFields = { ...stateWithoutEmptyFields, [property]: finallState[property] };
      }
    }
  }
  return stateWithoutEmptyFields;
};

// Craft the search query hours from 00:00:00 to 23:59:59:999 Local time
export const formatDateSince = (date) => date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null;
export const formatDateUntil = (date) => date ? new Date(date.setHours(23, 59, 59, 999)).toISOString() : null;
