import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import makeStyles from './styles';
import { Button, Grid, TextField } from '@material-ui/core';

const useStyles = makeStyles();

const Search = ({
  setText,
  stateSearch,
  onSearch
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const handleOnSubmit = useCallback(event => {
    event.preventDefault();
    onSearch(event);
  }, [onSearch]);

  return (
    <form onSubmit={handleOnSubmit}>
      <Grid container>
          <Grid item xs={9} className={classes.toolbarItem}>
            <TextField
              label={t('search')}
              variant="outlined"
              onChange={(e) => setText(e.target.value)}
              className={classes.searchText}
              size="medium"
              value={stateSearch.text ? stateSearch.text : null}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              type='submit'
              className={classes.addButton}
              size="medium"
              color="primary"
              variant="contained">
             {t('search')}
            </Button>
          </Grid>
      </Grid>
    </form>
  );
};

Search.propTypes = {
  setText: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  stateSearch: PropTypes.shape({
    text: PropTypes.string.isRequired
  }).isRequired
};

export default Search;
