import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, Link, TextField } from '@material-ui/core';
import { TrashIcon, URLGlobe, WarningIcon } from 'common/Icons';
import { DialogTitle, DialogButtons, DialogContent } from 'common/probes/ProbeDialog';
import useDialog from 'hooks/useDialog';
import { propTypes } from './UrlField.props';
import makeStyles from '../../UrlScanner.styles';
import getDefaultTheme from 'contexts/ThemeContext/defaultTheme';

const useStyles = makeStyles();
const defaultTheme = getDefaultTheme();

const UrlField = ({ index, url, onChange, onRemove, canDelete, isDisabled }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const onRemoveDialog = useDialog(`remove-url-${index}`, {
    onClose: () => {
      onRemoveDialog({ isOpen: false });
    },
    components: {
      Icon: <WarningIcon color={defaultTheme.palette.error.main} size='4rem' />,
      Title: <DialogTitle title= {t('delete_url_confirmation')} />,
      ContentText: <DialogContent content= {t('url_wont_be_scanned', { url: `${url}` })} />,
      Actions: <DialogButtons type='warning'
        confirmLabel={t('delete')} cancelLabel={t('cancel')}
        onConfirm={() => onRemove(index)}
        onCancel={() => onRemoveDialog({ isOpen: false })} />
    }
  });

  const handleOnDeleteLink = useCallback(event => {
    event.preventDefault();
    // Only confirm non empty URLs.
    if (url !== '') {
      onRemoveDialog({ isOpen: true });
    } else {
      onRemove(index);
    }
  }, [onRemoveDialog, onRemove, index, url]);

  return (
    <div key={index} className={classes.urlInputWrapper}>
      <TextField
        id="urloutlined-basic"
        placeholder={t('paste_url_here')}
        variant="outlined"
        value={url}
        onChange={(event) => { onChange(event, index); }}
        disabled={isDisabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <URLGlobe size={20}/>
            </InputAdornment>
          )
        }}
      />
      {canDelete &&
          <Link data-test-id='deleteInputLink' className={classes.deleteLink} href='#'
            onClick={handleOnDeleteLink}>
          <TrashIcon color='gray' size={16}/>
        </Link>}
    </div>
  );
};

UrlField.propTypes = propTypes;

export default UrlField;
