import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  chipResult: {
    textTransform: 'uppercase',
    width: 100
  },
  safe: {
    color: theme.palette.success.main
  },
  unsafe: {
    color: theme.palette.error.main
  },
  failed: {
    color: theme.palette.error.main
  },
  cancelled: {
    color: theme.palette.text.secondary
  },
  suspicious: {
    color: theme.palette.warning.main
  },
  undetermined: {
    color: '#167424'
  },
  clean: {
    color: '#167424'
  },
  unknown: {
    color: theme.palette.text.secondary
  },
  notAnalyzed: {
    color: theme.palette.text.secondary
  },
  warning: {
    color: theme.palette.warning.main
  },
  safeBackground: {
    backgroundColor: theme.palette.success.main
  },
  unsafeBackground: {
    backgroundColor: theme.palette.error.main
  },
  failedBackground: {
    backgroundColor: theme.palette.error.main
  },
  cancelledBackground: {
    backgroundColor: theme.palette.error.main
  },
  suspiciousBackground: {
    backgroundColor: theme.palette.warning.main
  },
  undeterminedBackground: {
    backgroundColor: '#167424'
  },
  cleanBackground: {
    backgroundColor: '#167424'
  },
  unknownBackground: {
    backgroundColor: theme.palette.text.secondary
  },
  notAnalyzedBackground: {
    backgroundColor: theme.palette.text.secondary
  },
  warningBackground: {
    backgroundColor: theme.palette.warning.main
  },
  grey: {
    backgroundColor: theme.palette.grey[400]
  },
  infoIcon: {
    width: '0.8rem',
    height: '0.8rem'
  },
  labelTooltip: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tooltip: {
    color: '#FFFFFF',
    fontFamily: theme.typography.caption.fontFamily,
    fontSize: 15,
    padding: 10
  },
  bar: {
    borderRadius: 20
  },
  rootProgress: {
    height: 5,
    borderRadius: 20
  }
}));
