import React, { useContext, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import _ from 'lodash';
import { IconButton, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CheckIcon, WarningIcon } from 'common/Icons';
import { AuthContext } from 'contexts/AuthContext';
import { fetchHealth } from 'entities/health/health.api';
import { useDialog } from 'hooks';
import { componentsHashMap, serviceStatusMessageMap, getStatusLabel } from './HealthStatusQF.utils';
import makeStyles from './HealthStatusQF.styles';

const useStyles = makeStyles();

export default function HealthStatusQF () {
  const [t] = useTranslation();
  const [healthInfo, setHealthInfo] = useState(null);
  const classes = useStyles();
  const { status, components } = healthInfo || {};

  useEffect(() => {
    fetchHealth({})
      .then(response => setHealthInfo(response.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    onDisplayDialog({ isOpen: false });
  };

  const content = (
    <Grid className={classes.dialogContent}>
      <h1 className={classes.textCenter}>
        {
          status === 'UP'
            ? <CheckIcon className={classes.successColor} />
            : <WarningIcon className={classes.warningColor} />
        }
      </h1>
      <h1 className={classes.textCenter}>{t('qflow_status', { status: t(`${serviceStatusMessageMap[status]}`) || t(`${serviceStatusMessageMap.DEGRADED}`) })}</h1>
      <p className={classes.textCenter}><Trans i18nKey="having_trouble_email_us">Having trouble? Email us at <a href='mailto:support@qflow.com'>support@qflow.com</a></Trans></p>
      <Grid container>
        {
          _.keys(components)
            .map((component, index) =>
              <Grid
                className={classes.componentItem}
                key={index}
                item
                container
                justify='space-between'
                xs={6}
              >
                <Grid item>
                  <strong>{ t(`${componentsHashMap[component]}`) }</strong>
                  <br />
                  <span className={classes.greyText}>{t('service_is', { status: getStatusLabel(components[component].status) })}</span>
                </Grid>
                <Grid item className={classes.iconContainer}>
                  {
                    status === 'UP'
                      ? <CheckIcon className={`${classes.smallIcon} ${classes.successColor}`} />
                      : <WarningIcon className={`${classes.smallIcon} ${classes.warningColor}`} />
                  }
                </Grid>
              </Grid>
            )
        }
      </Grid>
      <Grid container className={classes.statusContainer}>
        <Grid container item justify='center' alignItems='center' xs={6}>
          <WarningIcon className={`${classes.smallIcon} ${classes.warningColor} ${classes.marginRight}`} /> <span>{t('service_is_degraded')}</span>
        </Grid>
        <Grid container item justify='center' alignItems='center' xs={6}>
          <CheckIcon className={`${classes.smallIcon} ${classes.successColor} ${classes.marginRight}`} /> <span>{t('service_is_up')}</span>
        </Grid>
      </Grid>
    </Grid>
  );

  const onDisplayDialog = useDialog('health-doalog', {
    onClose: () => {
      onDisplayDialog({ isOpen: false });
    },
    components: {
      Icon: null,
      Title: (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ),
      Content: content
    }
  });

  const { auth } = useContext(AuthContext);

  return (
    healthInfo
      ? (
          <Grid container justify='center' className={classes.statusMessage}>
            {
              status === 'UP'
                ? <CheckIcon className={`${classes.smallIcon} ${classes.successColor}`} />
                : <WarningIcon className={`${classes.smallIcon} ${classes.warningColor}`} />
            }
            <span style={{ marginLeft: 10 }}>
              { t('qflow_status', { status: getStatusLabel(status) }) }
              {
                auth.session_token
                  ? (
                      <>
                        <span>{t('see_full_status')}</span>
                        <span
                          onClick={() => onDisplayDialog({ isOpen: true })}
                          className={classes.underline}>
                            {t('here.')}
                        </span>
                      </>
                    )
                  : null
              }
            </span>
          </Grid>
        )
      : null
  );
};
