import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TableRow, TableCell, Button } from '@material-ui/core';
import Item from './components/Item';
import { fetchFile } from 'entities/files/files.api';
import { propTypes } from './Row.props';

const Row = ({ fileId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [t] = useTranslation();
  const [file, setFile] = useState();

  const getFile = useCallback(async () => {
    try {
      setIsError(false);
      setIsLoading(true);
      const response = await fetchFile(fileId);
      setFile(response.data);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [fileId]);

  useEffect(() => {
    getFile();
  }, [getFile]);

  return (
    <>
      {isLoading && (<TableRow><TableCell colSpan={6} style={{ textAlign: 'center' }}><CircularProgress /></TableCell></TableRow>)}
      {isError && (<TableRow><TableCell colSpan={6} style={{ textAlign: 'right' }}><Button onClick={getFile} variant="contained" color="primary">{t('try_again')}</Button></TableCell></TableRow>)}
      {file && <Item data={file} />}
    </>
  );
};

Row.propTypes = propTypes;

export default Row;
