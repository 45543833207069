import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import { downloadFileContent, fetchFileContent } from 'entities/files/files.api';
import { DownloadRaw } from 'common/Icons';
import { propTypes } from './DownloadContent.props';

const iconStyle = {
  width: '1.5rem',
  height: '1.5rem'
};

export default function DownloadContent ({
  content
}) {
  const [t] = useTranslation();

  const classes = {
    root: {
      padding: 5
    }
  };

  const onDownload = async (e) => {
    if (e) {
      e.preventDefault();
    }

    try {
      const response = await fetchFileContent(content.id, {
        onDownloadProgress: (event) => {
          console.log(event);
        }
      });

      downloadFileContent(response.data, content);
    } catch (err) {
      console.log(err);
    }
  };

  return <IconButton onClick={onDownload} classes={classes}>
    <DownloadRaw style={iconStyle} title={t('download')}/>
  </IconButton>;
};

DownloadContent.propTypes = propTypes;
