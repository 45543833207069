import { bool, func, number, string } from 'prop-types';

export const propTypes = {
  url: string.isRequired,
  isDisabled: bool.isRequired,
  onChange: func.isRequired,
  onRemove: func.isRequired,
  canDelete: bool.isRequired,
  index: number.isRequired
};
