import React from 'react';
import { useTranslation } from 'react-i18next';
import { ANALYSIS_STATUS } from 'entities/analysis/analysis.constants';
import { QuestionCircle, WarningIcon, Close } from 'common/Icons';
import { propTypes } from './StatusIcon.props';
import makeStyles from './StatusIcon.styles';

const useStyles = makeStyles();

const StatusIcon = ({ currentAnalysis }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  if (currentAnalysis && !currentAnalysis.safety) {
    // checking analysis status
    switch (currentAnalysis?.status) {
      case ANALYSIS_STATUS.FAILED:
        return <Close className={classes.icon + ' ' + classes.failed} title={t('scan_failed')} color="primary"/>;
      case ANALYSIS_STATUS.RUNNING:
        return <QuestionCircle className={classes.icon} title={t('scan_in_progress')} color="primary"/>;
      case ANALYSIS_STATUS.PENDING:
        return <QuestionCircle className={classes.icon} title={t('scan_didnt_run')} color="primary"/>;
      case ANALYSIS_STATUS.CANCELED:
        return <WarningIcon className={classes.icon + ' ' + classes.cancelled} title={t('scan_cancelled')} color="primary"/>;
      default:
        // analysis status when running or pending is TRUE
        return null;
    }
  };
  return null;
};

StatusIcon.propTypes = propTypes;

export default StatusIcon;
