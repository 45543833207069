import tableConfig from 'common/tableConfig';

export default () => ({
  components: {
    ...tableConfig.components
  },
  options: {
    ...tableConfig.options
  }
});
