import { useState, useEffect } from 'react';
import { fetchMyFiles } from 'entities/files/files.api';
import { fetchURLs } from 'entities/urls/urls.api';
import { fetchTags } from 'entities/tags/tags.api';
import { fetchAccountsDetail } from 'entities/accounts/accounts.api';
import { fetchSelfUser, fetchUsers } from 'entities/users/users.api';
import { fetchCatalogProbes, DEFAULT_CATALOG } from 'entities/catalogs/catalogs.api';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';
import { isPlatformAdmin } from 'entities/users/users.utils';

export function useDashboardData () {
  const { isUrlAnalysisEnabled, isLoading } = useAppConfigContext();
  const [files, setFiles] = useState([]);
  const [urls, setURLs] = useState([]);
  const [tags, setTags] = useState([]);
  const [probesList, setProbesList] = useState([]);
  const [numberOfAccounts, setNumberOfAccounts] = useState();
  const [users, setUsers] = useState({});
  const [isDashboardLoading, setIsDashboardLoading] = useState(true);
  const [platformAdmin, setPlatformAdmin] = useState(false);

  const fetchCommonData = async () => {
    const lastYear = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

    const fileData = await fetchFiles(lastYear);
    const urlData = await fetchUrls(lastYear);

    return { files: fileData, urls: urlData };
  };

  const fetchFiles = async (lastYear) => {
    const filesResponse = await fetchMyFiles({ since: lastYear, range: '0-1000' });
    return filesResponse?.data;
  };

  const fetchUrls = async (lastYear) => {
    if (isUrlAnalysisEnabled) {
      try {
        const response = await fetchURLs({ since: lastYear, range: '0-1000' });
        return response.data;
      } catch (error) {
        return [];
      }
    } else {
      return [];
    }
  };

  const fetchAdminData = async () => {
    const commonData = await fetchCommonData();
    const getTags = await fetchTags();
    const tags = getTags?.data;

    const getUsers = await fetchUsers({});

    const userCount = getUsers.data.reduce((counts, obj) => {
      obj.roles.forEach(role => {
        const roleName = role.name;
        counts[roleName] = (counts[roleName] || 0) + 1;
      });
      return counts;
    }, {});

    const users = {
      operators: userCount?.operator ?? 0,
      analysts: userCount?.analyst ?? 0,
      administrators: userCount?.admin ?? 0
    };

    return { ...commonData, tags, users };
  };

  const fetchPlatformAdminData = async () => {
    const commonData = await fetchCommonData();
    const accounts = await fetchAccountsDetail();
    const probesResponse = await fetchCatalogProbes(DEFAULT_CATALOG);

    const numberOfAccounts = accounts.data.length;
    const probes = probesResponse.data;
    return { ...commonData, numberOfAccounts, probes };
  };

  const isUserPlatformAdmin = async () => {
    const response = await fetchSelfUser();
    const user = response?.data;
    return isPlatformAdmin(user);
  };

  const fetchData = async () => {
    setIsDashboardLoading(true);
    const platformAdmin = await isUserPlatformAdmin();
    setPlatformAdmin(platformAdmin);
    let data = null;
    if (platformAdmin) {
      data = await fetchPlatformAdminData();
      setNumberOfAccounts(data?.numberOfAccounts);
      setProbesList(data?.probes);
    } else {
      data = await fetchAdminData();
      setTags(data?.tags);
      setUsers(data?.users);
    }
    setFiles(data?.files);
    setURLs(data?.urls);
    setIsDashboardLoading(false);
  };

  useEffect(() => {
    !isLoading && fetchData();
    const timer = setInterval(() => fetchData(), 120000);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return {
    files,
    urls,
    tags,
    probesList,
    numberOfAccounts,
    users,
    isDashboardLoading,
    platformAdmin
  };
}
