import React from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import { propTypes } from './PageHeader.props';
import makeStyles from './PageHeader.styles';

const useStyles = makeStyles();

const PageHeader = ({ title, children, ...props }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems='center' classes={{ root: classes.pageTitle }} {...props}>
      <Grid item>
        <Typography variant="h4" component="span" gutterBottom>{title}</Typography>
      </Grid>
      {children && (
        <>
        <Divider classes={{ root: classes.divider }} orientation="vertical" flexItem />
        <Grid item>
          {children}
        </Grid>
        </>
      )}
    </Grid>
  );
};

PageHeader.propTypes = propTypes;

export default PageHeader;
