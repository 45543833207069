import HTTP from 'common/http';
import { createBlobToURL, createLinkAndDownload } from 'utils/downloadUtil';
import { MIME_TYPES } from './files.constants';

const FILES = 'files';
const TAGS = 'tags';

export const fetchMyFiles = (params = {}) => {
  const url = FILES;
  return HTTP.get(url, { params });
};

export const fetchFile = (id, params = {}) => {
  const url = `${FILES}/${id}`;
  return HTTP.get(url, { params });
};

export const fetchFileLatestResult = (id, params) => {
  const url = `${FILES}/${id}/results/latest`;
  return HTTP.get(url, { params });
};

export const deleteFile = (id, params) => {
  const url = `${FILES}/${id}/wipe`;
  return HTTP.post(url, { params });
};

export const uploadFile = async files => {
  const url = FILES;
  const headers = {
    'Content-Type': 'multipart/form-data'
  };
  const file = Array.isArray(files) ? files[0] : files;
  const formData = new FormData();
  formData.append('submitter', 'webui');
  formData.append('file', file.instance);
  if (file.password) {
    // for archiver with password ????
    formData.append('password', { password: file.password });
  }
  return HTTP.post(url, { headers, data: formData });
};

export const downloadFileContent = (stream, content, downloadType) => {
  const url = createBlobToURL({ stream, ...content });
  createLinkAndDownload(url, content.name, downloadType);
};

export const fetchFileContent = (id, { fetchAs = 'stream', password = '' }) => {
  const url = `${FILES}/${id}/content`;
  const headers = {
    'Content-Type': MIME_TYPES.APPLICATION[fetchAs]
  };
  return HTTP.get(url, { params: { password }, responseType: 'arraybuffer', headers });
};

// Setting the 'Content-Type' to zip will only work if data:null is sent with the request
export const fetchFileContentAsZip = (id, { password = '' }) => {
  const url = `${FILES}/${id}/content`;
  const headers = {
    'Content-Type': MIME_TYPES.APPLICATION.zip
  };
  return HTTP.get(url, { params: { password }, responseType: 'arraybuffer', headers, data: null });
};

// TAGS
export const addTagToFile = (id, params) => {
  const url = `${FILES}/${id}/${TAGS}`;
  return HTTP.put(url, { params });
};

export const deleteTagFromFile = (id, params) => {
  const url = `${FILES}/${id}/${TAGS}`;
  return HTTP.delete(url, { params });
};
