import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles((theme) => ({
  link: {
    display: 'flex',
    width: '100%'
  },
  icon: {
    width: '1rem',
    height: '1rem'
  },
  extracted: {
    height: '350px',
    overflowX: 'hidden',
    marginRight: '-50px',
    overflow: 'auto',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#ddd'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#999'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#666'
    },
    marginTop: '20px'
  }
}));
