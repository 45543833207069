import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { disableProbe } from 'entities/probes/probes.api';
import { isExpiringSoon, formatExpiryDate, daysUntilExpiry } from './ProbeCard.utils';
import { STATUS, MSG } from './ProbeCard.constants';
import PropTypes from 'prop-types';
import makeStyles from './styles';

export default function ProbeStatus ({ id, licenseInfo, setEnablePossible, probe }) {
  const [t] = useTranslation();
  const useStyles = makeStyles();
  const classes = useStyles();
  const isEnabled = probe.enabledVersion !== null;

  const getDisplayStatus = (licenseInfo = []) => {
    const { invalid, expires, errors } = licenseInfo;
    const daysRemaining = daysUntilExpiry(expires);
    const translationKey = daysRemaining > 1 ? 'expires_in_other' : 'expires_in_one';

    switch (true) {
      case errors && errors.length > 0:
        return { status: STATUS.ERROR, expiryDateValue: MSG.NOT_SET };
      case invalid:
        return { status: STATUS.ERROR, expiryDateValue: MSG.EXPIRED };
      case expires && isExpiringSoon(expires):
        return { status: STATUS.WARNING, expiryDateValue: t(translationKey, { days: daysRemaining }) };
      case 'expires' in licenseInfo:
        return { status: STATUS.ACTIVE, expiryDateValue: formatExpiryDate(expires) };
      default:
        return { status: STATUS.ACTIVE, expiryDateValue: MSG.FREE };
    }
  };

  const { status, expiryDateValue } = getDisplayStatus(licenseInfo);

  if (status === STATUS.ERROR) {
    setEnablePossible(false);
    if (isEnabled) {
      disableProbe(id);
    }
  }

  const typographyClasses = {
    overline: status === STATUS.WARNING ? classes.warning : status === STATUS.ERROR ? classes.error : classes.active
  };

  return (
    <div className={classes.cardItemsWrapper}>
      <div className={classes.cardItems}>
        <Typography variant="caption" display="block" color="textSecondary" gutterBottom>
        {t('status')}
        </Typography>
        <Typography className={typographyClasses.overline}>
          &#8226; {t(`${status}`)}
        </Typography>
      </div>
      <div className={classes.cardItems}>
        <Typography variant="caption" display="block" color="textSecondary" gutterBottom>
        {t('expiration_date')}
        </Typography>
        <Typography className={typographyClasses.overline}>
        {t(`${expiryDateValue}`)}
        </Typography>
      </div>
    </div>
  );
}
ProbeStatus.propTypes = {
  licenseInfo: PropTypes.object,
  setEnablePossible: PropTypes.func,
  probe: PropTypes.object,
  id: PropTypes.string
};
